import React from 'react';
import {
    withStyles,
} from '@material-ui/core/styles';
import {
    AppBar,
    Button,
    CircularProgress,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tabs,
    TextField,
    Typography,
} from '@material-ui/core';
// import PropTypes from 'prop-types';
import { DatePicker, DateTimePicker } from 'material-ui-pickers';
import AuthorisedIcon from '@material-ui/icons/CheckCircleOutline';
import DeclinedIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import UnassignIcon from '@material-ui/icons/AssignmentReturn';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import {
    Link,
    DirectLink,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller,
} from 'react-scroll';
import { withSnackbar } from 'notistack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Error';
import CrossCircleIcon from '@material-ui/icons/Cancel';
import BulletIcon from '@material-ui/icons/FiberManualRecord';
import AddIcon from '@material-ui/icons/Add';
import SwapIcon from '@material-ui/icons/SwapHoriz';
import {
    get,
    isEmpty,
    sortBy,
} from 'lodash';
import moment from 'moment-timezone';
import GaugeChart from 'react-gauge-chart';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import HybridTextField from '../../hybridTextField';
import ContactAgreementImages from './contactAgreementImages';
import ContactAgreementItems from './contactAgreementItems';
import ContactAgreementPayments from './contactAgreementPayments';
import AgreementPaymentSummary from '../agreementPaymentSummary';
import titalise from '../../../utilities/titalise';
import Tile from '../../../containers/tile';
import './react-pdf.css';
import {
    paymentPlanStatus,
    scheduleFrequencies,
} from '../../../utilities/menuOptions';
import EngineerSchedule from '../engineerSchedule';
import { visitPointsArray } from '../../../constants/arrays';
import ContactHistory from '../contactHistory';
import capitalisedSnakeCaseToTitleCase from '../../../utilities/capitalisedSnakeCaseToTitleCase';
import classNames from 'classnames';
import { paymentSourceLookUp } from '../../../utilities/lookUpTables';
import { AddAPhoto } from '@material-ui/icons';
import AgreementItemAddDialog from '../agreementItemAddDialog';
import AgreementItemExchangeDialog from '../agreementItemExchangeDialog';
import ContactAgreementBadges from '../contactAgreementBadges';
import AppBarBottom from '../appBarBottom';
import { FLOORING_PRODUCT_CODE } from '../../../constants/static';

const AMPLIFY5 = 1002;

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const styles = (theme) => ({
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 7,
        textAlign: 'left',
        width: '100%',
    },
    tileBottomDivider: {
        marginTop: theme.spacing.unit * 3,
        width: '100%',
    },
    bottomBarContainer: {
        textAlign: 'right',
        width: '100%',
    },
    textButton: {
        marginBottom: 0,
        marginTop: theme.spacing.unit * 1.5,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    hybridTextIconLeft: {
        fontSize: 16,
        marginRight: theme.spacing.unit * 1,
        transform: 'translate(0%, 13%)',
    },
    hybridTextIconRight: {
        fontSize: 16,
        marginLeft: theme.spacing.unit * 1,
        transform: 'translate(0%, 13%)',
    },
    cancelledIcon: {
        color: '#d7d7d7',
        // color: '#f1180b',
    },
    warmIcon: {
        color: '#E7C251',
    },
    coldIcon: {
        color: '#BBFFFF',
    },
    notSetIcon: {
        color: '#d7d7d7',
    },
    activeIcon: {
        color: '#4caf50',
    },
    warningIcon: {
        color: '#fac518',
    },
    alert: {
        color: '#f1180b',
    },
    alertContainer: {
        /* text-align: left; */
        border: '1px solid',
        borderRadius: 4,
        fontWeight: 400,
        marginBottom: theme.spacing.unit * 4,
        padding: theme.spacing.unit * 2,
        width: '100%',
    },
    deliveriesAlertContainer: {
        /* text-align: left; */
        backgroundColor: '#f1180b',
        borderRadius: 4,
        color: '#fff',
        fontWeight: 600,
        padding: theme.spacing.unit * 2,
        width: '100%',
    },
    deliveriesOKContainer: {
        /* text-align: left; */
        backgroundColor: '#4caf50',
        borderRadius: 4,
        color: '#fff',
        fontWeight: 600,
        padding: theme.spacing.unit * 2,
        width: '100%',
    },
    bumpBottom4U: {
        marginBottom: theme.spacing.unit * 4,
    },
    bumpBottom3U: {
        marginBottom: theme.spacing.unit * 3,
    },
    bumpBottom2U: {
        marginBottom: theme.spacing.unit * 2,
    },
    bumpBottom1U: {
        marginBottom: theme.spacing.unit * 1,
    },
    warning: {
        color: '#ff9800',
    },

    tabsPaper: {
        alignItems: 'center',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        paddingBottom: 24,
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 32,
        width: '100%',
    },
    tabsAppBar: {
        backgroundColor: theme.palette.primary.lighter,
    },
    tab: {
        color: 'white',
        fontSize: 15,
        fontWeight: 500,
        // height: 64,
        minHeight: 38,
    },
    tabs: {
        // height: 64,
        minHeight: 38,
        padding: 0,
    },
    tabButton: {
        backgroundColor: 'red',
        height: 64,
        minHeight: 38,
        padding: 0,
    },
    inputLabel: {
        backgroundColor: '#fff',
        marginLeft: -5,
        paddingLeft: 8,
        paddingRight: 8,
    },
    formInstructions: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 3,
    },
    statusSelect: {
        width: 200,
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        width: '100%',
    },
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 5,
    },
    dialogContent: {
        paddingBottom: 16,
        minWidth: 390,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    radioDescription: {
        marginLeft: 34,
        marginRight: theme.spacing.unit * 3,
        marginTop: -8,
    },
    issuerRadioDescription: {
        color: theme.palette.text.secondary,
        margin: 0,
    },
    disabledText: {
        color: 'rgba(0, 0, 0, 0.38)',
    },
    radioButtonsContainer: {
        marginLeft: theme.spacing.unit * 3,
    },
    menuItemText: {
        color: theme.palette.text.primary,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
    },
    menuItemSubtext: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
    },
    selectMultiline: {
        height: 75,
    },
    convertDialogContent: {
        paddingBottom: 0,
        width: 540,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontSize: 14,
        fontWeight: 'bold',
        height: 48,
    },
    tableHeadRow: {
        height: 48,
    },
    tableIndex: {
        paddingTop: theme.spacing.unit,
        verticalAlign: 'top',
    },
    marginTop3: {
        marginTop: theme.spacing.unit * 3,
    },
    inputAdornment: {
        color: theme.palette.text.secondary,
        fontSize: 16,
        marginTop: 1,
    },
    sectionContainer: {
        borderBottom: '1px solid #eaeaea',
        marginBottom: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 1,
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        textAlign: 'center',
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    declinedStatusIcon: {
        color: red[400],
        fontSize: 100,
        margin: theme.spacing.unit * 2,
    },
    authorisedStatusIcon: {
        color: green[400],
        fontSize: 100,
        margin: theme.spacing.unit * 2,
    },
    ErrorStatusIcon: {
        color: amber[400],
        fontSize: 100,
        margin: theme.spacing.unit * 2,
    },
    cardRadio: {
        paddingTop: '0px',
    },
    issuerRadioLabel: {
        marginTop: theme.spacing.unit * 1,
    },
    emptyContent: {
        padding: 32,
    },
    textField: {
        width: 260,
    },
    bumpTop1U: {
        marginTop: theme.spacing.unit * 1,
    },
    bumpTop2U: {
        marginTop: theme.spacing.unit * 2,
    },
    bumpTop3U: {
        marginTop: theme.spacing.unit * 2,
    },
    bumpTop3U: {
        marginTop: theme.spacing.unit * 3,
    },
    bumpTop4U: {
        marginTop: theme.spacing.unit * 4,
    },
    bumpLeft3U: {
        marginLeft: theme.spacing.unit * 3,
    },
    bulletIcon: {
        fontSize: 11,
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 1.5,
    },
    deliveryAlertList: {
        fontWeight: 400,
        marginTop: theme.spacing.unit * 1,
    },
    subTile: {
        marginBottom: theme.spacing.unit * 4,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    gaugeContainer: {
        width: 100,
        // marginLeft: 'auto',
        // marginRight: 'auto',
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 24px',
        margin: '0px 0px 0px 0px !important',
    },
    pdfDialogContent: {
        minWidth: 300,
        minHeight: 300,
    },
    signatureImage: {
        border: '1px solid darkgrey',
        boxShadow: '5px 5px 5px 1px #ccc',
        borderRadius: 5,
        maxWidth: 680,
    },
    dialogActionsRight: {
        textAlign: 'right',
    },
    dialogDownloadButton: {
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '8px',
        fontSize: '0.875rem',
        minWidth: '64px',
        boxSizing: 'border-box',
        minHeight: '36px',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '500',
        lineHeight: '1.5',
        borderRadius: '4px',
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        border: '0',
        margin: '0',
        cursor: 'pointer',
        display: 'inline-flex',
        outline: 'none',
        position: 'relative',
        alignItems: 'center',
        userSelect: 'none',
        verticalAlign: 'middle',
        justifyContent: 'center',
        textDecoration: 'none',
        backgroundColor: 'transparent',
        webkitAppearance: 'none',
        webkitTapHighlightColor: 'transparent',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    signatureDialogContent: {
        textAlign: 'center',
    },
    checkboxIndent: {
        marginLeft: theme.spacing.unit * 3,
    },
    buildSendContractDialog: {
        maxWidth: 400,
    },
    emailStatementDialog: {
        maxWidth: 400,
    },
    engineerScheduleDialogContent: {
        textAlign: 'center',
    },
    updatedDateTimes: {
        marginTop: theme.spacing.unit * 4,
    },
    pleaseWait: {
        marginTop: 10,
        fontWeight: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
        opacity: '.5',
        marginBottom: 72,
    },
    pleaseWaitSpinnerContainer: {
        marginTop: 24,
        marginBottom: 24,
    },
    resultIcon: {
        fontSize: 94,
    },
    error: {
        color: '#ff0000',
    },
    warning: {
        color: '#f9aa33',
    },
    success: {
        color: '#4caf50',
    },
    stateContainer: {
        marginBottom: 32,
        marginTop: 32,
        textAlign: 'center',
    },
    resultLabel: {
        marginTop: 10,
        fontWeight: 700,
    },
    resultprogress: {
        opacity: '.5',
        width: '94px !important',
        height: '94px !important',
    },
    dialogContentAlertText: {
        color: '#c80000',
        fontSize: 16,
        opacity: 0.54,
    },
    dialogContentTitle: {
        color: '#c80000',
        fontSize: 16,
        opacity: 0.54,
        fontWeight: 700,
    },
    blackTableIcons: {
        color: '#000000',
        fontSize: 22,
        opacity: 0.6,
    },
    customTableCell: {
        verticalAlign: 'middle',
        marginTop: 16,
    },
    editItemAgreementTableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    editAgreementItemTable: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
        border: '1px solid #e0e0e0',
    },
    editAgreementItemList: {
        marginLeft: theme.spacing.unit * 4,
        marginTop: theme.spacing.unit * 2,
        color: '#c80000',
    },
    dialogTitle: {
        marginBottom: theme.spacing.unit * 4,
    },
    cardIssuer: {
        color: '#a3a3a3',
    },
    costCentreLogo: {
        maxHeight: 100,
    },
    leadNoteContainer: {
        borderStyle: 'solid',
        borderWidth: 1,
        padding: '16px 16px 0px 16px',
        borderRadius: 8,
        borderColor: '#7e919d30',
        backgroundColor: 'aliceblue',
        marginBottom: 16,
    },
    stampWrapper: {
        position: 'relative',
        display: 'inline-block',
    },
    agreementStamp: {
        boxShadow: '0 0 0 3px #ffbf00, 0 0 0 2px #ffbf00 inset',
        border: '2px solid transparent',
        borderRadius: 4,
        display: 'inline-block',
        padding: '5px 2px',
        color: '#ffbf00',
        fontSize: 270,
        textTransform: 'uppercase',
        textAlign: 'center',
        opacity: 0.6,
        fontWeight: 900,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'rotate(-5deg) translate(-50%,-50%)',
        minWidth: '80%',
        marginTop: 140,
    },
});

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        paddingRight: 8,
    },
    body: {
        fontSize: 12,
        verticalAlign: 'top',
        paddingTop: 16,
        paddingBottom: 8,
        paddingRight: 8,
    },
}))(TableCell);

const currentDate = moment.tz(moment(), 'Europe/London');
class ContactAgreements extends React.Component {
    state = {
        controlDeviceForm: {
            command: 'ON',
        },
        currentEditAgreementItem: null,
        disableBuildSendContractSubmitButton: false,
        disableAgreementItemConfirmDeleteButton: false,
        disableAgreementItemConfirmUnassignButton: false,
        disableAutoPhonePaymentSendButton: false,
        disableEditAgreementSubmitButton: false,
        disableSelfMeasurementCheckedButton: false,
        // disableEditAgreementItemsSubmitButton: false,
        disableEditPaymentPlanSubmitButton: false,
        disableRetryPaymentSubmitButton: false,
        disableInterimPaymentSubmitButton: false,
        disableManualPaymentSubmitButton: false,
        disableManualAdjustmentSubmitButton: false,
        disableEmailStatementSubmitButton: false,
        disableControlDeviceSendButton: false,
        disableRefundPaymentsSubmitButton: false,
        disableRequestSignatureButton: false,
        disableStandingOrderPaymentSubmitButton: false,
        editAgreementData: {},
        editAgreementItemsData: {},
        editPaymentPlanData: {},
        retryPaymentData: {},
        interimPaymentData: {},
        manualAdjustmentData: {},
        manualPaymentData: {},
        manualPaymentResult: false,
        processingManualPayment: false,
        refundPaymentsData: {},
        requestSignatureData: {
            action: 'SMS',
        },
        showAutoPhonePaymentDialog: false,
        showEditAgreementDialog: false,
        showManualPaymentDialog: false,
        showManualAdjustmentDialog: false,
        showManualPaymentResultDialog: false,
        showSendTokenisationLinkDialog: false,
        showControlDeviceDialog: false,
        showSendDeliveryInviteDialog: false,
        showScheduleDeliveryDialog: false,
        showEditAgreementItemsDialog: false,
        showDeleteAgreementItemDialog: false,
        showUnassignAgreementItemDialog: false,
        showAddAgreementItemDialog: false,
        showExchangeAgreementItemDialog: false,
        showEditPaymentPlanDialog: false,
        showRetryPaymentDialog: false,
        showInterimPaymentDialog: false,
        showBuildSendContractDialog: false,
        showEngineerSchedulerDialog: false,
        showEmailStatementDialog: false,
        showCheckSelfMeasurementDialog: false,
        showRefundPaymentsDialog: false,
        showRequestSignatureDialog: false,
        showStandingOrderPaymentDialog: false,
        standingOrderPaymentData: {},
        // showUpdatePaymentPlanStatusDialog: false,
        disableSubmitManualPaymentButton: false,
        disableScheduleDeliverySubmitButton: false,
        disableSendDeliveryInviteSendButton: false,
        disableManualPaymentNextButton: true,
        buildSendContractData: {
            sendToEmailAddress: '',
        },
        emailStatementData: {
            sendToEmailAddress: '',
        },
        scheduleDeliveryData: {
            agreementItems: [],
            dateTime: '',
        },
        sendDeliveryInviteData: {
            agreementItems: [],
            dateTime: '',
            installationAmount: '',
        },
        disableUpdatePaymentPlanSubmitButton: false,
        currentAgreementTab: 0,
        showPdfDialog: false,
        showSignatureDialog: false,
        showOnlyBranchEngineers: true,
    };

    async componentDidMount() {
        await this.changeAgreementTab();
    }

    componentDidUpdate(prevProps) {
        if (this.props.requestedAgreementTab !== prevProps.requestedAgreementTab) {
            this.changeAgreementTab();
        }
    }

    changeAgreementTab = async () => {
        if (this.props.requestedAgreementTab >= 0) {
            await this.scrollToAgreementsTop();
            this.setState({
                value: this.props.requestedAgreementTab,
            });
        }
    };

    // scrollToAgreementsTop = () => {
    //     scroller.scrollTo('agreementsTop', {
    //         duration: 800,
    //         delay: 100,
    //         smooth: 'easeInOutQuart',
    //         offset: -90,
    //     });
    // }

    // capitaliseFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

    // agreementStatusIcon = (alertStatus, warningStatus, cancelledStatus) => {
    //     switch (true) {
    //     case (alertStatus): {
    //         return (<WarningIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.alert}`} />);
    //     }
    //     case (warningStatus): {
    //         return (<WarningIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.warningIcon}`} />);
    //     }
    //     case (cancelledStatus): {
    //         return (<CrossCircleIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.cancelledIcon}`} />);
    //     }
    //     default: {
    //         return (<CheckCircleIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.activeIcon}`} />);
    //     }
    //     // case 'Warm': {
    //     //     return (this.props.classes.warmIcon);
    //     // }
    //     // case 'Cold': {
    //     //     return (this.props.classes.coldIcon);
    //     // }
    //     // case 'Finance': {
    //     //     return (this.props.classes.financeIcon);
    //     // }
    //     // default: {
    //     //     return (this.props.classes.notSetIcon);
    //     // }
    //     }
    // }

    // handleManualPaymentClick = () => {
    //     this.setState(state => ({
    //         showManualPaymentDialog: true,
    //     }));
    // }

    // handleSendTokenisationLinkClick = () => {
    //     this.setState(state => ({
    //         showSendTokenisationLinkDialog: true,
    //     }));
    // }

    // handleControlDeviceClick = () => {
    //     const currentControlDeviceState = (((this.props.customer.agreements[this.state.value] || {}).items || []).find(item => item.controlDeviceCode !== null) || {}).controlDeviceState;
    //     this.setState({
    //         controlDeviceForm: {
    //             command: currentControlDeviceState === 'OFF' ? 'ON' : 'OFF',
    //         },
    //         showControlDeviceDialog: true,
    //     });
    // }

    handleScheduleDeliveryClick = () => {
        const currentAgreement = this.props.contact.agreements[this.state.currentAgreementTab];
        const now = moment.tz(moment(), 'Europe/London');
        const dateTime = moment(now).format('YYYY-MM-DD');
        this.setState((state) => ({
            scheduleDeliveryData: {
                ...state.scheduleDeliveryData,
                agreementItems: [],
                dateTime,
            },
            showScheduleDeliveryDialog: true,
        }));
    };

    handleEditAgreementClick = () => {
        const currentAgreement = this.props.contact.agreements[this.state.currentAgreementTab];
        this.setState({
            editAgreementData: {
                createdByContactCode: currentAgreement.createdByContactCode || '',
                installationIncluded: currentAgreement.installationIncluded ? 1 : 0,
                minimumPreDeliveryPayments: currentAgreement.minimumPreDeliveryPayments,
                productTypeCode: currentAgreement.productTypeCode || '',
                specialInstruction: currentAgreement.specialInstruction,
                status: currentAgreement.status,
            },
            showEditAgreementDialog: true,
        });
    };

    handleEditAgreementItemsClick = () => {
        const currentAgreement = this.props.contact.agreements[this.state.currentAgreementTab];
        const currentAgreementItems = currentAgreement.agreementItems.reduce((acc, agreementItem) => [...acc,
            {
                code: agreementItem.code,
                modelNo: agreementItem.product.modelNo,
                productCode: agreementItem.product.code,
            }], []);
        this.setState({
            editAgreementItemsData: {
                agreementItems: currentAgreementItems,
            },
            showEditAgreementItemsDialog: true,
        });
    };

    handleEditPaymentPlanClick = () => {
        const currentAgreement = this.props.contact.agreements[this.state.currentAgreementTab];
        this.setState({
            editPaymentPlanData: {
                cardExpiry: currentAgreement.cardExpiry,
                cardNo: currentAgreement.cardNo,
                cardToken: currentAgreement.plan.cardToken,
                nextPaymentDate: currentAgreement.plan.nextPaymentDate,
                reason: '',
                recurringPaymentAmount: currentAgreement.plan.recurringPaymentAmount,
                repaymentAmount: currentAgreement.plan.repaymentAmount,
                scheduleDay: currentAgreement.plan.scheduleDay,
                scheduleDay2: currentAgreement.plan.scheduleDay2,
                scheduleFrequency: currentAgreement.plan.scheduleFrequency,
                scheduleInterval: currentAgreement.plan.scheduleInterval,
                status: currentAgreement.plan.status,
                type: currentAgreement.plan.type,
            },
            showEditPaymentPlanDialog: true,
        });
    };

    handleRetryPaymentClick = () => {
        const currentAgreement = this.props.contact.agreements[this.state.currentAgreementTab];
        this.setState({
            retryPaymentData: {
                retryPaymentDateTime: currentDate.format('YYYY-MM-DD HH:mm'),
                reason: '',
            },
            showRetryPaymentDialog: true,
        });
    };

    handleInterimPaymentClick = () => {
        const currentAgreement = this.props.contact.agreements[this.state.currentAgreementTab];
        this.setState({
            interimPaymentData: {
                interimPaymentAmount: currentAgreement.plan.recurringPaymentAmount,
                interimPaymentDate: currentDate.format('YYYY-MM-DD HH:mm'),
                reason: '',
            },
            showInterimPaymentDialog: true,
        });
    };

    handleManualPaymentClick = () => {
        const currentAgreement = this.props.contact.agreements[this.state.currentAgreementTab];
        this.setState({
            manualPaymentData: {
                action: 'SMS',
                amount: currentAgreement.plan.recurringPaymentAmount,
                reason: '',
                requestType: (this.props.paymentCards || []).length === 0 ? 'LINK' : 'EXISTING_CARD',
            },
            manualPaymentDialogState: 'FORM',
            showManualPaymentDialog: true,
        });
    };

    handleAutoPhonePaymentClick = () => {
        this.setState({
            showAutoPhonePaymentDialog: true,
        });
    };

    handleManualAdjustmentClick = () => {
        this.setState({
            manualAdjustmentData: {
                reason: '',
            },
            showManualAdjustmentDialog: true,
        });
    };

    handleStandingOrderPaymentClick = () => {
        this.setState({
            standingOrderPaymentData: {
                reason: '',
            },
            showStandingOrderPaymentDialog: true,
        });
    };

    handleRefundPaymentsClick = () => {
        this.setState({
            refundPaymentsData: {
                reason: '',
                requestType: 'ALL_PAYMENTS',
                refundPayments: [],
            },
            showRefundPaymentsDialog: true,
        });
    };

    handleBuildSendContractClick = () => {
        const currentAgreement = this.props.contact.agreements[this.state.currentAgreementTab];
        this.setState((state) => ({
            buildSendContractData: {
                ...state.buildSendContractData,
            },
            showBuildSendContractDialog: true,
        }));
    };

    handleEmailStatementClick = () => {
        const currentAgreement = this.props.contact.agreements[this.state.currentAgreementTab];
        this.setState((state) => ({
            emailStatementData: {
                ...state.emailStatementData,
                sendToEmailAddress: this.props.contact.emailAddress,
            },
            showEmailStatementDialog: true,
        }));
    };

    handleEngineerSchedulerClick = () => {
        this.setState({
            showEngineerSchedulerDialog: true,
        });
    };

    handleCheckSelfMeasurementClick = () => {
        this.setState({
            showCheckSelfMeasurementDialog: true,
        });
    };

    handleSendDeliveryInviteClick = () => {
        const currentAgreement = this.props.contact.agreements[this.state.currentAgreementTab];
        const now = moment.tz(moment(), 'Europe/London');
        const dateTime = moment(now).format('YYYY-MM-DD');
        this.setState((state) => ({
            sendDeliveryInviteData: {
                ...state.sendDeliveryInviteData,
                agreementItems: [],
                dateTime,
            },
            showSendDeliveryInviteDialog: true,
        }));
    };

    // handleUpdatePaymentPlanStatusClick = () => {
    //     this.setState({
    //         paymentPlanStatusData: {
    //             Status: this.props.customer.agreements[this.state.value].Status,
    //         },
    //         showUpdatePaymentPlanStatusDialog: true,
    //     });
    // }

    // handleCloseUpdatePaymentPlanStatusDialogClick = () => {
    //     this.setState({
    //         showUpdatePaymentPlanStatusDialog: false,
    //     });
    // }

    // handleCloseManualPaymentDialogClick = () => {
    //     this.setState(state => ({
    //         showManualPaymentDialog: false,
    //         manualPaymentStage: 'amount',
    //     }));
    // }

    // handleCloseSendTokenisationLinkDialogClick = () => {
    //     this.setState(state => ({
    //         showSendTokenisationLinkDialog: false,
    //         sendTokenisationLinkStage: 'method',
    //         sendTokenisationLinkForm: {
    //             messageType: 'sms',
    //             destination: 'current',
    //         },
    //     }));
    // }

    // handleCloseControlDeviceDialogClick = () => {
    //     this.setState(state => ({
    //         showControlDeviceDialog: false,
    //     }));
    // }

    handleCloseBuildSendContractDialogClick = () => {
        this.setState((state) => ({
            showBuildSendContractDialog: false,
            disableBuildSendContractSubmitButton: false,
        }));
    };

    handleCloseEngineerSchedulerDialogClick = () => {
        this.setState((state) => ({
            showEngineerSchedulerDialog: false,
        }));
    };

    handleCloseEmailStatementDialogClick = () => {
        this.setState((state) => ({
            showEmailStatementDialog: false,
            disableEmailStatementSubmitButton: false,
        }));
    };

    handleCloseCheckSelfMeasurementDialogClick = () => {
        this.setState((state) => ({
            showCheckSelfMeasurementDialog: false,
            disableSelfMeasurementCheckedButton: false,
        }));
    };

    handleCloseScheduleDeliveryDialogClick = () => {
        this.setState((state) => ({
            showScheduleDeliveryDialog: false,
            disableScheduleDeliverySubmitButton: false,
        }));
    };

    handleCloseEditAgreementDialogClick = () => {
        this.setState((state) => ({
            showEditAgreementDialog: false,
            disableEditAgreementSubmitButton: false,
        }));
    };

    handleCloseEditAgreementItemsDialogClick = () => {
        this.setState((state) => ({
            showEditAgreementItemsDialog: false,
            // disableEditAgreementItemsSubmitButton: false,
        }));
    };

    handleCloseDeleteAgreementItemDialogClick = () => {
        this.setState((state) => ({
            showDeleteAgreementItemDialog: false,
            // disableEditAgreementItemsSubmitButton: false,
        }));
    };

    handleCloseUnassignAgreementItemDialogClick = () => {
        this.setState((state) => ({
            showUnassignAgreementItemDialog: false,
            // disableEditAgreementItemsSubmitButton: false,
        }));
    };

    handleCloseAddAgreementItemDialogClick = () => {
        this.setState((state) => ({
            showAddAgreementItemDialog: false,
            // disableEditAgreementItemsSubmitButton: false,
        }));
    };

    handleCloseExchangeAgreementItemDialogClick = () => {
        this.setState((state) => ({
            showExchangeAgreementItemDialog: false,
        }));
    };

    handleCloseEditPaymentPlanDialogClick = () => {
        this.setState((state) => ({
            showEditPaymentPlanDialog: false,
            // disableEditPaymentPlanSubmitButton: false,
        }));
    };

    handleCloseRetryPaymentDialogClick = () => {
        this.setState((state) => ({
            showRetryPaymentDialog: false,
            disableRetryPaymentSubmitButton: false,
        }));
    };

    handleCloseSendDeliveryInviteDialogClick = () => {
        this.setState((state) => ({
            showSendDeliveryInviteDialog: false,
            disableSendDeliveryInviteSendButton: false,
        }));
    };

    handleCloseInterimPaymentDialogClick = () => {
        this.setState((state) => ({
            showInterimPaymentDialog: false,
            disableInterimPaymentSubmitButton: false,
        }));
    };

    handleCloseManualPaymentDialogClick = () => {
        this.setState((state) => ({
            disableManualPaymentSubmitButton: false,
            showManualPaymentDialog: false,
        }));
    };

    handleCloseAutoPhonePaymentDialogClick = () => {
        this.setState((state) => ({
            showAutoPhonePaymentDialog: false,
        }));
    };

    handleCloseManualAdjustmentDialogClick = () => {
        this.setState({
            disableManualAdjustmentSubmitButton: false,
            showManualAdjustmentDialog: false,
        });
    };

    handleCloseStandingOrderPaymentDialogClick = () => {
        this.setState({
            disableStandingOrderPaymentSubmitButton: false,
            showStandingOrderPaymentDialog: false,
        });
    };

    handleCloseRefundPaymentsDialogClick = () => {
        this.setState({
            disableRefundPaymentsSubmitButton: false,
            showRefundPaymentsDialog: false,
        });
    };

    handleCloseManualPaymentResultDialogClick = () => {
        this.setState({
            manualPaymentResult: null,
        });
    };

    handleRequestSignatureClick = () => {
        this.setState({
            showRequestSignatureDialog: true,
        });
    };

    handleCloseRequestSignatureDialogClick = () => {
        this.setState({
            showRequestSignatureDialog: false,
        });
    };

    // handleManualPaymentNextClick = async () => {
    //     let manualPaymentStageEnum;
    //     manualPaymentStageEnum = this.state.manualPaymentStages.indexOf(this.state.manualPaymentStage);
    //     manualPaymentStageEnum += 1;
    //     switch (this.state.manualPaymentStages[manualPaymentStageEnum]) {
    //     case 'cards':
    //         this.setState({ disableManualPaymentNextButton: true });
    //         this.setState({
    //             disableManualPaymentNextButton: false,
    //         });
    //         break;
    //     case 'payment':
    //         this.setState({
    //             transactionStatusWaiting: true,
    //             disableManualPaymentNextButton: true,
    //         });
    //         const amount = this.state.manualPaymentForm.amount;
    //         const agreementCode = this.props.customer.agreements[this.state.value].code;
    //         const paymentCard = this.state.manualPaymentForm.paymentCard;
    //         if (paymentCard === 'new') {
    //             manualPaymentStageEnum += 1; // Change manualPaymentStage to 'result'
    //             this.newCardManualPayment(agreementCode, amount);
    //         } else {
    //             const cardNo = this.props.paymentCards.find(card => card.token === paymentCard).cardNo;
    //             manualPaymentStageEnum += 1; // Change manualPaymentStage to 'result'
    //             await this.tokenManualPayment(agreementCode, amount, paymentCard, cardNo).then((paymentTransactionStatus) => {
    //                 this.setState({
    //                     transactionStatusWaiting: false,
    //                     manualPaymentTransctionStatus: paymentTransactionStatus,
    //                 });
    //             });
    //         }
    //         break;
    //     }
    //     this.setState({
    //         disableManualPaymentNextButton: false,
    //         manualPaymentStage: this.state.manualPaymentStages[manualPaymentStageEnum],
    //     });
    // }

    // handleSendTokenisationLinkNextClick = async () => {
    //     let sendTokenisationLinkStageEnum;
    //     sendTokenisationLinkStageEnum = this.state.sendTokenisationLinkStages.indexOf(this.state.sendTokenisationLinkStage);
    //     sendTokenisationLinkStageEnum += 1;
    //     this.setState(state => ({
    //         sendTokenisationLinkStage: state.sendTokenisationLinkStages[sendTokenisationLinkStageEnum],
    //     }));
    // }

    // updateManualPaymentData = (event) => {
    //     const { name, value } = event.target;
    //     let formattedValue;
    //     switch (name) {
    //     case 'amount':
    //         formattedValue = value.replace(/[^0-9$.]/g, '');
    //         this.setState({
    //             disableManualPaymentNextButton: !parseInt(formattedValue) > 0,
    //         });
    //         break;
    //     default:
    //         formattedValue = value;
    //         break;
    //     }
    //     event.target.value = formattedValue;

    //     this.setState({
    //         manualPaymentForm: {
    //             ...this.state.manualPaymentForm,
    //             [name]: formattedValue,
    //         },
    //     });
    // }

    // updatePaymentPlanStatusData = (event) => {
    //     const { name, value } = event.target;
    //     event.target.value = value;

    //     this.setState({
    //         paymentPlanStatusData: {
    //             [name]: value,
    //         },
    //     });
    // }

    // updateControlDeviceData = (event) => {
    //     // debugger;
    //     const { name, value } = event.target;
    //     let formattedValue;
    //     switch (name) {
    //     default:
    //         formattedValue = value;
    //         break;
    //     }
    //     event.target.value = formattedValue;

    //     this.setState(state => ({
    //         controlDeviceForm: {
    //             ...state.controlDeviceForm,
    //             [name]: formattedValue,
    //         },
    //     }));
    // }

    updateScheduleDeliveryData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            scheduleDeliveryData: {
                ...state.scheduleDeliveryData,
                [name]: formattedValue,
            },
        }));
    };

    updateAgreementData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            editAgreementData: {
                ...state.editAgreementData,
                [name]: formattedValue,
            },
        }));
    };

    updateAgreementItemsData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            editAgreementItemsData: {
                ...state.editAgreementItemsData,
                [name]: formattedValue,
            },
        }));
    };

    updatePaymentPlanData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        let additionalValues;
        const currentPaymentCard = (this.props.paymentCards || []).find((paymentCard) => paymentCard.token === value);
        switch (name) {
        case 'scheduleDay':
            formattedValue = parseInt(value.replace(/[^0-9$]/g, ''), 10) > 31 ? this.state.editPaymentPlanData.scheduleDay : value.replace(/[^0-9$]/g, '');
            break;
        case 'scheduleDay2':
            formattedValue = parseInt(value.replace(/[^0-9$]/g, ''), 10) > 31 ? this.state.editPaymentPlanData.scheduleDay2 : value.replace(/[^0-9$]/g, '');
            break;
        case 'scheduleInterval':
            formattedValue = value.replace(/[^0-9$]/g, '');
            break;
        case 'reason':
            formattedValue = value === ' ' ? '' : value;
            break;
        case 'cardToken':
            formattedValue = value;
            additionalValues = {
                cardExpiry: currentPaymentCard.expiry,
                cardNo: currentPaymentCard.cardNo,
            };
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            editPaymentPlanData: {
                ...state.editPaymentPlanData,
                ...additionalValues,
                [name]: formattedValue,
            },
        }));
    };

    updateRetryPaymentData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'retryPaymentAmount':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            retryPaymentData: {
                ...state.retryPaymentData,
                [name]: formattedValue,
            },
        }));
    };

    updateInterimPaymentData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'interimPaymentAmount':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            interimPaymentData: {
                ...state.interimPaymentData,
                [name]: formattedValue,
            },
        }));
    };

    updateManualPaymentData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'amount':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            manualPaymentData: {
                ...state.manualPaymentData,
                [name]: formattedValue,
            },
        }));
    };

    updateRequestSignatureData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            requestSignatureData: {
                ...state.requestSignatureData,
                [name]: formattedValue,
            },
        }));
    };

    updateManualAdjustmentData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'amount':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            manualAdjustmentData: {
                ...state.manualAdjustmentData,
                [name]: formattedValue,
            },
        }));
    };

    updateStandingOrderPaymentData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'amount':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            standingOrderPaymentData: {
                ...state.standingOrderPaymentData,
                [name]: formattedValue,
            },
        }));
    };

    updateRefundPaymentsData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            refundPaymentsData: {
                ...state.refundPaymentsData,
                [name]: formattedValue,
            },
        }));
    };

    updateBuildSendContractData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            buildSendContractData: {
                ...state.buildSendContractData,
                [name]: formattedValue,
            },
        }));
    };

    updateEmailStatementData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            emailStatementData: {
                ...state.emailStatementData,
                [name]: formattedValue,
            },
        }));
    };

    updateSendDeliveryInviteData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'installationAmount': formattedValue = value.replace(/^(\d+\.?\d?\d?)?.*/, '$1');
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            sendDeliveryInviteData: {
                ...state.sendDeliveryInviteData,
                [name]: formattedValue,
            },
        }));
    };

    // updateSendTokenisationLinkData = (event) => {
    //     const { name, value } = event.target;
    //     let formattedValue;
    //     switch (true) {
    //     case (this.state.sendTokenisationLinkForm.messageType === 'sms' && name === 'to'):
    //         console.log('sms & to');
    //         formattedValue = value.replace(/[^0-9$.]/g, '');
    //         this.setState({
    //             disableSendTokenisationLinkSendButton: formattedValue.length === 0,
    //         });
    //         break;
    //     case (this.state.sendTokenisationLinkForm.messageType === 'email' && name === 'to'):
    //         console.log('email & to');
    //         formattedValue = value;
    //         this.setState({
    //             disableSendTokenisationLinkSendButton: formattedValue.length === 0,
    //         });
    //         break;
    //     case (value === 'current'):
    //         formattedValue = value;
    //         this.setState({
    //             disableSendTokenisationLinkSendButton: false,
    //         });
    //         break;
    //     default:
    //         formattedValue = value;
    //         break;
    //     }
    //     event.target.value = formattedValue;

    //     this.setState({
    //         sendTokenisationLinkForm: {
    //             ...this.state.sendTokenisationLinkForm,
    //             [name]: formattedValue,
    //         },
    //     });
    // }

    // handleSendTokenisationLink = (event) => {
    //     event.preventDefault();
    //     this.setState({
    //         disableSendTokenisationLinkSendButton: true,
    //     });
    //     const agreementCode = this.props.customer.agreements[this.state.value].code;
    //     this.props.sendTokenisationLink(agreementCode, this.state.sendTokenisationLinkForm).then(() => {
    //         this.setState(state => ({
    //             showSendTokenisationLinkDialog: false,
    //             disableSendTokenisationLinkSendButton: false,
    //             sendTokenisationLinkStage: 'method',
    //             sendTokenisationLinkForm: {
    //                 messageType: 'sms',
    //                 destination: 'current',
    //             },
    //         }));
    //         this.props.enqueueSnackbar('Tokenisation Link Sent', { variant: 'success' });
    //     }).catch(() => {
    //         console.log(this.props.errors);
    //         this.props.enqueueSnackbar('Send Tokenisation Link failed', { variant: 'error' });
    //     });
    // }

    // handleSendControlDeviceCommand = (event) => {
    //     event.preventDefault();
    //     this.setState({
    //         disableControlDeviceSendButton: true,
    //     });
    //     const controlDeviceCode = this.props.customer.agreements[this.state.value].items.find(item => item.controlDeviceCode != null).controlDeviceCode;
    //     const contactCode = this.props.customer.code;
    //     const command = this.state.controlDeviceForm.command;

    //     this.props.sendControlDeviceCommand(controlDeviceCode, contactCode, command).then(() => {
    //         this.setState(state => ({
    //             showControlDeviceDialog: false,
    //             disableControlDeviceSendButton: false,
    //         }));
    //         this.props.enqueueSnackbar('Conrol device command sent', { variant: 'success' });
    //     }).catch(() => {
    //         console.log(this.props.errors);
    //         this.props.enqueueSnackbar('Control device command send failed', { variant: 'error' });
    //     });
    // }

    // handlePrintDeliveryLabelsClick = () => {
    //     const agreementCode = this.props.customer.agreements[this.state.value].code;
    //     this.props.printDeliveryLabels(agreementCode).then(() => {
    //         this.props.enqueueSnackbar('Delivery Labels Printed', { variant: 'success' });
    //     }).catch(() => {
    //         console.log(this.props.errors);
    //         this.props.enqueueSnackbar('Delivery Labels Print failed', { variant: 'error' });
    //     });
    // }

    // handleCancelAgreementClick = () => {
    //     const agreementCode = this.props.customer.agreements[this.state.value].code;
    //     const params = {
    //         status: 'CANCELLED',
    //     };
    //     this.props.cancelAgreement(agreementCode, params).then(() => {
    //         this.props.enqueueSnackbar('Agreement Cancelled', { variant: 'success' });
    //     }).catch(() => {
    //         console.log(this.props.errors);
    //         this.props.enqueueSnackbar('Agreement Cancel failed', { variant: 'error' });
    //     });
    // }

    // handleSubmitUpdatePaymentPlanStatusClick = () => {
    //     this.setState({
    //         disableUpdatePaymentPlanSubmitButton: true,
    //         showUpdatePaymentPlanStatusDialog: false,
    //     });
    //     const agreementCode = this.props.customer.agreements[this.state.value].code;
    //     const params = {
    //         userReference: this.props.customer.code,
    //         planReference: agreementCode,
    //         status: this.state.paymentPlanStatusData.Status,
    //     };
    //     this.props.updatePaymentPlanStatus(agreementCode, params).then(() => {
    //         this.setState({
    //             disableUpdatePaymentPlanSubmitButton: false,
    //         });
    //         this.props.enqueueSnackbar('Payment Plan Updated', { variant: 'success' });
    //     }).catch(() => {
    //         console.log(this.props.errors);
    //         this.props.enqueueSnackbar('Payment Plan Update failed', { variant: 'error' });
    //     });
    // }

    handleRequestSignature = () => {
        this.setState({
            disableScheduleDeliverySubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        this.props.requestSignature(agreementCode, this.state.requestSignatureData.action).then(() => {
            this.setState({
                disableScheduleDeliverySubmitButton: false,
                showRequestSignatureDialog: false,
            });
            this.props.enqueueSnackbar('Agreement Signature Request Sent', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableScheduleDeliverySubmitButton: false,
            });
            this.props.enqueueSnackbar('Agreement Signature Request failed', { variant: 'error' });
        });
    };

    handleScheduleDeliverySaveClick = () => {
        this.setState({
            disableScheduleDeliverySubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        const params = {
            ...this.state.scheduleDeliveryData,
        };
        this.props.scheduleDelivery(agreementCode, params).then(() => {
            this.setState({
                disableScheduleDeliverySubmitButton: false,
                showScheduleDeliveryDialog: false,
            });
            this.props.enqueueSnackbar('Agreement Delivery Updated', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableScheduleDeliverySubmitButton: false,
            });
            this.props.enqueueSnackbar('Agreement Delivery Update failed', { variant: 'error' });
        });
    };

    handleEditAgreementSaveClick = () => {
        this.setState({
            disableEditAgreementSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        const params = {
            ...this.state.editAgreementData,
            installationIncluded: !!this.state.editAgreementData.installationIncluded,
        };
        this.props.editAgreement(agreementCode, params).then(() => {
            this.setState({
                disableEditAgreementSubmitButton: false,
                showEditAgreementDialog: false,
            });
            this.props.enqueueSnackbar('Agreement Edit Saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableEditAgreementSubmitButton: false,
            });
            this.props.enqueueSnackbar('Agreement Edit failed', { variant: 'error' });
        });
    };

    handleAgreementItemConfirmDeleteClick = (params) => {
        const currentEditAgreementItemCode = this.state.currentEditAgreementItem.code;
        this.setState({
            disableAgreementItemConfirmDeleteButton: true,
            currentEditAgreementItem: null,
            showDeleteAgreementItemDialog: false,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        this.props.deleteAgreementItem(agreementCode, this.state.currentEditAgreementItem.code, params).then(() => {
            this.setState({
                disableAgreementItemConfirmDeleteButton: false,
            });
            this.props.enqueueSnackbar('Agreement Item Deleted', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableAgreementItemConfirmDeleteButton: false,
            });
            this.props.enqueueSnackbar('Delete Agreement Item failed', { variant: 'error' });
        });
    };

    handleAgreementItemConfirmUnassignClick = (params) => {
        const currentEditAgreementItemCode = this.state.currentEditAgreementItem.code;
        this.setState({
            disableAgreementItemConfirmUnassignButton: true,
            currentEditAgreementItem: null,
            showUnassignAgreementItemDialog: false,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        this.props.unassignAgreementItem(agreementCode, this.state.currentEditAgreementItem.code, params).then(() => {
            this.setState({
                disableAgreementItemConfirmUnassignButton: false,
            });
            this.props.enqueueSnackbar('Agreement Item Unassigned', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableAgreementItemConfirmUnassignButton: false,
            });
            this.props.enqueueSnackbar('Unassign Agreement Item failed', { variant: 'error' });
        });
    };

    handleAgreementItemConfirmAddClick = (params) => {
        const currentEditAgreementItemCode = this.state.currentEditAgreementItem.code;
        this.setState({
            disableAgreementItemConfirmAddButton: true,
            currentEditAgreementItem: null,
            showAddAgreementItemDialog: false,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        this.props.AddAgreementItem(agreementCode, this.state.currentEditAgreementItem.code, params).then(() => {
            this.setState({
                disableAgreementItemConfirmAddButton: false,
            });
            this.props.enqueueSnackbar('Agreement Item Add', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableAgreementItemConfirmAddButton: false,
            });
            this.props.enqueueSnackbar('Add Agreement Item failed', { variant: 'error' });
        });
    };

    handleAgreementItemConfirmExchangeClick = (params) => {
        const currentEditAgreementItemCode = this.state.currentEditAgreementItem.code;
        this.setState({
            disableAgreementItemConfirmExchangeButton: true,
            currentEditAgreementItem: null,
            showExchangeAgreementItemDialog: false,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        this.props.ExchangeAgreementItem(agreementCode, this.state.currentEditAgreementItem.code, params).then(() => {
            this.setState({
                disableAgreementItemConfirmExchangeButton: false,
            });
            this.props.enqueueSnackbar('Agreement Item Exchange', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableAgreementItemConfirmExchangeButton: false,
            });
            this.props.enqueueSnackbar('Exchange Agreement Item failed', { variant: 'error' });
        });
    };

    handleEditPaymentPlanSaveClick = () => {
        this.setState({
            disableEditPaymentPlanSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        const params = {
            ...this.state.editPaymentPlanData,
            nextPaymentDate: this.state.editPaymentPlanData.nextPaymentDate,
            recurringPaymentAmount: parseFloat(this.state.editPaymentPlanData.recurringPaymentAmount, 10),
            repaymentAmount: parseFloat(this.state.editPaymentPlanData.repaymentAmount, 10),
            // scheduleDay: (this.state.editPaymentPlanData.scheduleFrequency === 'MONTHLY'
            // || this.state.editPaymentPlanData.scheduleFrequency === 'QUARTERLY') ? moment(this.state.editPaymentPlanData.nextPaymentDate).date() : undefined,
            scheduleDay: (this.state.editPaymentPlanData.scheduleFrequency === 'MONTHLY' || this.state.editPaymentPlanData.scheduleFrequency === 'TWICEMONTHLY') ? this.state.editPaymentPlanData.scheduleDay : undefined,
            scheduleDay2: (this.state.editPaymentPlanData.scheduleFrequency === 'TWICEMONTHLY') ? this.state.editPaymentPlanData.scheduleDay2 : undefined,
            scheduleFrequency: this.state.editPaymentPlanData.scheduleFrequency,
            scheduleInterval: this.state.editPaymentPlanData.scheduleInterval,
            status: this.state.editPaymentPlanData.status,
        };
        this.props.editPaymentPlan(agreementCode, params).then(() => {
            this.setState({
                disableEditPaymentPlanSubmitButton: false,
                showEditPaymentPlanDialog: false,
            });
            this.props.enqueueSnackbar('Payment Plan Edit Saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableEditPaymentPlanSubmitButton: false,
            });
            this.props.enqueueSnackbar('Payment Plan Edit failed', { variant: 'error' });
        });
    };

    handleRetryPaymentSaveClick = () => {
        this.setState({
            disableRetryPaymentSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        const params = {
            ...this.state.retryPaymentData,
            retryPaymentAmount: parseFloat(this.state.retryPaymentData.retryPaymentAmount, 10),
        };
        this.props.scheduleRetryPayment(agreementCode, params).then(() => {
            this.setState({
                disableRetryPaymentSubmitButton: false,
                showRetryPaymentDialog: false,
            });
            this.props.enqueueSnackbar('Retry Payment Scheduled', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableRetryPaymentSubmitButton: false,
            });
            this.props.enqueueSnackbar('Scehdule Retry Payment failed', { variant: 'error' });
        });
    };

    handleInterimPaymentSaveClick = () => {
        this.setState({
            disableInterimPaymentSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        const params = {
            ...this.state.interimPaymentData,
            interimPaymentAmount: parseFloat(this.state.interimPaymentData.interimPaymentAmount, 10),
        };
        this.props.scheduleInterimPayment(agreementCode, params).then(() => {
            this.setState({
                disableInterimPaymentSubmitButton: false,
                showInterimPaymentDialog: false,
            });
            this.props.enqueueSnackbar('Interim Payment Scheduled', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableInterimPaymentSubmitButton: false,
            });
            this.props.enqueueSnackbar('Scehdule Interim Payment failed', { variant: 'error' });
        });
    };

    handleManualPaymentSubmitClick = () => {
        this.setState({
            disableManualPaymentSubmitButton: true,
            manualPaymentResult: 'PROCESSING',
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        const params = {
            ...this.state.manualPaymentData,
            amount: parseFloat(this.state.manualPaymentData.amount, 10),
        };
        this.props.manualPayment(agreementCode, params).then((result) => {
            this.setState({
                disableManualPaymentSubmitButton: false,
                manualPaymentResult: result,
                showManualPaymentDialog: false,
            });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableManualPaymentSubmitButton: false,
            });
            this.props.enqueueSnackbar('Manual Payment request failed', { variant: 'error' });
        });
    };

    handleManualAdjustmentSubmitClick = () => {
        this.setState({
            disableManualAdjustmentSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        const params = {
            ...this.state.manualAdjustmentData,
            amount: parseFloat(this.state.manualAdjustmentData.amount),
        };
        this.props.manualAdjustment(agreementCode, params).then(() => {
            this.setState({
                disableManualAdjustmentSubmitButton: false,
                showManualAdjustmentDialog: false,
            });
            this.props.enqueueSnackbar('Manual Adjustment Successful', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableManualAdjustmentSubmitButton: false,
            });
            this.props.enqueueSnackbar('Manual Adjustment Failed', { variant: 'error' });
        });
    };

    handleStandingOrderPaymentSubmitClick = () => {
        this.setState({
            disableStandingOrderPaymentSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        const params = {
            ...this.state.standingOrderPaymentData,
            amount: parseFloat(this.state.standingOrderPaymentData.amount),
        };
        this.props.standingOrderPayment(agreementCode, params).then(() => {
            this.setState({
                disableStandingOrderPaymentSubmitButton: false,
                showStandingOrderPaymentDialog: false,
            });
            this.props.enqueueSnackbar('Standing Order Payment Successful', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableStandingOrderPaymentSubmitButton: false,
            });
            this.props.enqueueSnackbar('Standing Order Payment Failed', { variant: 'error' });
        });
    };

    handleRefundPaymentPlanSubmitClick = () => {
        this.setState({
            disableRefundPaymentsSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        const params = {
            reason: this.state.refundPaymentsData.reason,
        };
        this.props.refundPaymentPlan(agreementCode, params).then(() => {
            this.setState({
                disableRefundPaymentsSubmitButton: false,
                showRefundPaymentsDialog: false,
            });
            this.props.enqueueSnackbar('Payment Plan Refund request successful', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableRefundPaymentsSubmitButton: false,
            });
            this.props.enqueueSnackbar('Payment Plan Refund request failed', { variant: 'error' });
        });
    };

    handleRefundPaymentsSubmitClick = () => {
        this.setState({
            disableRefundPaymentsSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        const params = {
            ...this.state.refundPaymentsData,
        };
        this.props.refundPayments(agreementCode, params).then(() => {
            this.setState({
                disableRefundPaymentsSubmitButton: false,
                showRefundPaymentsDialog: false,
            });
            this.props.enqueueSnackbar('Refund Payments request successful', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableManualPaymentSubmitButton: false,
            });
            this.props.enqueueSnackbar('Refund Payments request failed', { variant: 'error' });
        });
    };

    handlePaymentLinkSubmitClick = () => {
        this.setState({
            disableManualPaymentSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        const params = {
            ...this.state.manualPaymentData,
            amount: parseFloat(this.state.manualPaymentData.amount, 10),
        };
        this.props.paymentLink(agreementCode, params).then(() => {
            this.setState({
                disableManualPaymentSubmitButton: false,
                showManualPaymentDialog: false,
            });
            this.props.enqueueSnackbar('Manual Payment request successful', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableManualPaymentSubmitButton: false,
            });
            this.props.enqueueSnackbar('Manual Payment request failed', { variant: 'error' });
        });
    };

    handleAutoPhonePaymentSendClick = () => {
        this.setState({
            disableAutoPhonePaymentSendButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        this.props.autoPhonePaymentSms(agreementCode).then(() => {
            this.setState({
                disableAutoPhonePaymentSendButton: false,
                showAutoPhonePaymentDialog: false,
            });
            this.props.enqueueSnackbar('Auto Phone Payment SMS successful', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableAutoPhonePaymentSendButton: false,
            });
            this.props.enqueueSnackbar('Auto Phone Payment SMS failed', { variant: 'error' });
        });
    };

    handleBuildSendContractSendClick = () => {
        this.setState({
            disableBuildSendContractSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        const params = {
            ...this.state.buildSendContractData,
        };
        this.props.buildSendContract(agreementCode, params).then(() => {
            this.setState({
                disableBuildSendContractSubmitButton: false,
                showBuildSendContractDialog: false,
            });
            this.props.enqueueSnackbar('Contract built and sent', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableBuildSendContractSubmitButton: false,
            });
            this.props.enqueueSnackbar('Build and send contract failed', { variant: 'error' });
        });
    };

    handleEmailStatementSendClick = () => {
        this.setState({
            disableEmailStatementSubmitButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        const params = {
            ...this.state.emailStatementData,
        };
        this.props.emailStatement(agreementCode, params).then(() => {
            this.setState({
                disableEmailStatementSubmitButton: false,
                showEmailStatementDialog: false,
            });
            this.props.enqueueSnackbar('Agreement Statement Sent', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableEmailStatementSubmitButton: false,
            });
            this.props.enqueueSnackbar('Send Agreement Statement Failed', { variant: 'error' });
        });
    };

    handleSelfMeasurementCheckedClick = () => {
        this.setState({
            disableSelfMeasurementCheckedButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        this.props.selfMeasurementChecked(agreementCode).then(() => {
            this.setState({
                disableSelfMeasurementCheckedButton: false,
                showCheckSelfMeasurementDialog: false,
            });
            this.props.enqueueSnackbar('Self Measurement Checked', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableEmailStatementSubmitButton: false,
            });
            this.props.enqueueSnackbar('Check Self Measurement Failed', { variant: 'error' });
        });
    };

    handleSendDeliveryInviteSendClick = () => {
        this.setState({
            disableSendDeliveryInviteSendButton: true,
        });
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        const formattedInstallationAmount = this.state.sendDeliveryInviteData.installationAmount ? parseInt(this.state.sendDeliveryInviteData.installationAmount, 10) : null;
        const params = {
            ...this.state.sendDeliveryInviteData,
            installationAmount: formattedInstallationAmount,
        };
        this.props.sendDeliveryInvite(agreementCode, params).then(() => {
            this.setState({
                disableSendDeliveryInviteSendButton: false,
                showSendDeliveryInviteDialog: false,
            });
            this.props.enqueueSnackbar('Agreement Delivery Invite Sent', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableSendDeliveryInviteSendButton: false,
            });
            this.props.enqueueSnackbar('Send Agreement Delivery Invite Failed', { variant: 'error' });
        });
    };

    handleEmailContractClick = () => {
        const agreementCode = this.props.contact.agreements[this.state.currentAgreementTab].code;
        this.props.emailContract(agreementCode).then(() => {
            this.props.enqueueSnackbar('Contract and T&C\'s email sent', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Send Contract and T&C\'s email failed', { variant: 'error' });
        });
    };

    // newCardManualPayment = (agreementCode, amount) => {
    //     this.props.manualPayment({
    //         agreementCode,
    //         amount,
    //         paymentExpiry: 90,
    //         paymentReturnURL: `https://${window.location.hostname}/customer/${this.props.customer.code}?paymentType=manual&agreementCode=${agreementCode}`,
    //     });
    // }

    // tokenManualPayment = (agreementCode, amount, token, cardNo) => this.props.tokenManualPayment({
    //     agreementCode,
    //     paymentAmount: amount,
    //     paymentCardToken: token,
    //     paymentCardNo: cardNo,
    // })

    // handleTryAgainClick = () => {
    //     this.setState({
    //         manualPaymentStage: 'amount',
    //     });
    // }

    handleAgreementChange = (event, newValue) => {
        this.setState({ currentAgreementTab: newValue });
    };

    // handleDebuggerClick = () => {
    //     debugger;
    // }

    updateScheduleDeliveryAgreementItemCheckbox = (name) => (event) => {
        const newState = event.target.checked ? 1 : 0;
        const newAgreementItems = this.state.scheduleDeliveryData.agreementItems.reduce((acc, agreementItem) => {
            if (agreementItem.code !== name) return [...acc, agreementItem];
            return [...acc];
        }, []);

        if (newState === 1) {
            newAgreementItems.push({
                action: 'DELIVER',
                agreementCode: this.props.contact.agreements[this.state.currentAgreementTab].code,
                code: name,
            });
        }

        this.setState((state) => ({
            scheduleDeliveryData: {
                ...state.scheduleDeliveryData,
                agreementItems: newAgreementItems,
            },
        }));
    };

    updateRefundPaymentCheckbox = (name, amount, source, paymentDateTime) => (event) => {
        const newState = event.target.checked ? 1 : 0;
        const newRefundPayments = this.state.refundPaymentsData.refundPayments.reduce((acc, refundPayment) => {
            if (refundPayment.paymentID !== name) return [...acc, refundPayment];
            return [...acc];
        }, []);

        if (newState === 1) {
            newRefundPayments.push({
                amount,
                paymentID: name,
                source,
                paymentDateTime,
            });
        }

        this.setState((state) => ({
            refundPaymentsData: {
                ...state.refundPaymentsData,
                refundPayments: newRefundPayments,
            },
        }));
    };

    updateSendDeliveryInviteAgreementItemCheckbox = (name) => (event) => {
        const newState = event.target.checked ? 1 : 0;
        const newAgreementItems = this.state.sendDeliveryInviteData.agreementItems.reduce((acc, agreementItem) => {
            if (agreementItem.code !== name) return [...acc, agreementItem];
            return [...acc];
        }, []);

        if (newState === 1) {
            newAgreementItems.push({
                action: 'DELIVER',
                agreementCode: this.props.contact.agreements[this.state.currentAgreementTab].code,
                code: name,
            });
        }

        this.setState((state) => ({
            sendDeliveryInviteData: {
                ...state.sendDeliveryInviteData,
                agreementItems: newAgreementItems,
            },
        }));
    };

    handleScheduleDeliveryDateChange = (chosenDate) => {
        this.setState((state) => ({
            scheduleDeliveryData: {
                ...state.scheduleDeliveryData,
                dateTime: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    handleRetryPaymentDateTimeChange = (chosenDate) => {
        this.setState((state) => ({
            retryPaymentData: {
                ...state.retryPaymentData,
                retryPaymentDateTime: moment(chosenDate).format('YYYY-MM-DD HH:mm'),
            },
        }));
    };

    handleInterimPaymentDateChange = (chosenDate) => {
        this.setState((state) => ({
            interimPaymentData: {
                ...state.interimPaymentData,
                interimPaymentDate: moment(chosenDate).format('YYYY-MM-DD HH:mm'),
            },
        }));
    };

    handleEditPaymentPlanNextPaymentDateChange = (chosenDate) => {
        this.setState((state) => ({
            editPaymentPlanData: {
                ...state.editPaymentPlanData,
                nextPaymentDate: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    handleSendDeliveryInviteDateChange = (chosenDate) => {
        this.setState((state) => ({
            sendDeliveryInviteData: {
                ...state.sendDeliveryInviteData,
                dateTime: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    updateShowOnlyBranchEngineersCheckbox = (name) => (event) => {
        const newState = !!event.target.checked;
        this.setState((state) => ({
            scheduleDeliveryData: {
                ...state.scheduleDeliveryData,
                engineerContactCode: undefined,
            },
            showOnlyBranchEngineers: newState,
        }));
    };

    // handleRecordDeliveryDateTimeChange = (chosenDate) => {
    //     this.setState(state => ({
    //         scheduleDeliveryData: {
    //             ...state.scheduleDeliveryData,
    //             dateTime: moment(chosenDate).format('YYYY-MM-DD HH:mm'),
    //         },
    //     }));
    // }

    handlePdfDialogOpen = (documentType) => {
        this.setState({
            documentType,
            showPdfDialog: true,
        });
    };

    handlePdfDialogClose = () => {
        this.setState({
            showPdfDialog: false,
        });
    };

    handleSignatureDialogOpen = (documentType) => {
        this.setState({
            documentType,
            showSignatureDialog: true,
        });
    };

    handleSignatureDialogClose = () => {
        this.setState({
            showSignatureDialog: false,
        });
    };

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({
            numPages,
        });
    };

    visitFromSchedule = (engineerContactCode, visitDateTime) => {
        this.setState((state) => ({
            scheduleDeliveryData: {
                ...state.scheduleDeliveryData,
                engineerContactCode,
                dateTime: moment(visitDateTime).format('YYYY-MM-DD'),
            },
            sendDeliveryInviteData: {
                ...state.sendDeliveryInviteData,
                engineerContactCode,
                dateTime: moment(visitDateTime).format('YYYY-MM-DD'),
            },
            showEngineerSchedulerDialog: false,
        }));
    };

    engineerScheduleEngingeerCode = () => {
        switch (true) {
        case (this.state.showScheduleDeliveryDialog): {
            return this.state.scheduleDeliveryData.engineerContactCode;
        }
        case (this.state.showSendDeliveryInviteDialog): {
            return this.state.sendDeliveryInviteData.engineerContactCode;
        }
        default: return null;
        }
    };

    engineerScheduleDateTime = () => {
        switch (true) {
        case (this.state.showScheduleDeliveryDialog): {
            return (moment(this.state.scheduleDeliveryData.dateTime).format('YYYY-MM-DD'));
        }
        case (this.state.showSendDeliveryInviteDialog): {
            return (moment(this.state.sendDeliveryInviteData.dateTime).format('YYYY-MM-DD'));
        }
        default: return null;
        }
    };

    // handleDeleteAgreementItemClick = async (agreementItemCode) => {
    //     const currentAgreement = this.props.contact.agreements[this.state.currentAgreementTab];
    //     const currentEditAgreementItem = currentAgreement.agreementItems.find((agreementItem) => agreementItem.code === agreementItemCode);

    //     this.setState({
    //         currentEditAgreementItem,
    //         showDeleteAgreementItemDialog: true,
    //     });
    // };

    handleDeleteAgreementItemClick = async (currentEditAgreementItem) => {
        this.setState({
            currentEditAgreementItem,
            showDeleteAgreementItemDialog: true,
        });
    };

    handleUnassignAgreementItemClick = async (currentEditAgreementItem) => {
        this.setState({
            currentEditAgreementItem,
            showUnassignAgreementItemDialog: true,
        });
    };

    handleAddAgreementItemClick = async () => {
        this.setState({
            showAddAgreementItemDialog: true,
        });
    };

    handleExchangeAgreementItemClick = async (currentEditAgreementItem) => {
        this.setState({
            currentEditAgreementItem,
            showExchangeAgreementItemDialog: true,
        });
    };

    updateAgreementDataCheckbox = (name) => (event) => {
        const newState = event.target.checked ? 1 : 0;
        this.setState((state) => ({
            editAgreementData: {
                ...state.editAgreementData,
                [name]: newState,
            },
        }));
    };

    render() {
        const {
            addNewHistoryNote,
            agreementStatuses,
            agreementsAlerts,
            assignStockItem,
            branches,
            classes,
            contact,
            contactAgreementsLoading,
            contactHistoriesLoading,
            contactLoading,
            contactTerminatedCall,
            contactVisitsLoading,
            emailContract,
            engineers,
            engineersLoading,
            errors,
            escalateCall,
            exchangeAgreementItem,
            fetchAgreementsData,
            fetchHistoriesData,
            fetchLeadsAgreementsData,
            fetchNextCall,
            logCall,
            paymentCards,
            paymentCardsLoading,
            productTypes,
            scheduleCallBack,
            scheduleDelivery,
            // customer,
            // paymentCards,
            // requestedAgreementTab,
            // transactionStatusWaiting,
            user,
            userLoading,
            users,
            usersLoading,
        } = this.props;
        const {
            buildSendContractData,
            currentAgreementTab,
            currentEditAgreementItem,
            disableAgreementItemConfirmDeleteButton,
            disableAgreementItemConfirmUnassignButton,
            disableAutoPhonePaymentSendButton,
            disableBuildSendContractSubmitButton,
            disableEditAgreementSubmitButton,
            // disableEditAgreementItemsSubmitButton,
            disableEditPaymentPlanSubmitButton,
            disableRetryPaymentSubmitButton,
            disableInterimPaymentSubmitButton,
            disableManualPaymentSubmitButton,
            disableManualAdjustmentSubmitButton,
            disableEmailStatementSubmitButton,
            disableSelfMeasurementCheckedButton,
            disableScheduleDeliverySubmitButton,
            disableSendDeliveryInviteSendButton,
            disableRefundPaymentsSubmitButton,
            disableRequestSignatureButton,
            disableStandingOrderPaymentSubmitButton,
            documentType,
            editAgreementData,
            editAgreementItemsData,
            editPaymentPlanData,
            retryPaymentData,
            interimPaymentData,
            emailStatementData,
            manualPaymentData,
            manualAdjustmentData,
            manualPaymentResult,
            processingManualPayment,
            refundPaymentsData,
            requestSignatureData,
            // numPages,
            scheduleDeliveryData,
            sendDeliveryInviteData,
            // controlDeviceForm,
            // disableControlDeviceSendButton,
            // disableManualPaymentNextButton,
            showBuildSendContractDialog,
            showEditAgreementDialog,
            showEditAgreementItemsDialog,
            showDeleteAgreementItemDialog,
            showUnassignAgreementItemDialog,
            showAddAgreementItemDialog,
            showExchangeAgreementItemDialog,
            showEditPaymentPlanDialog,
            showRetryPaymentDialog,
            showInterimPaymentDialog,
            showAutoPhonePaymentDialog,
            showManualPaymentDialog,
            showManualAdjustmentDialog,
            showManualPaymentResultDialog,
            showEmailStatementDialog,
            showEngineerSchedulerDialog,
            showCheckSelfMeasurementDialog,
            showOnlyBranchEngineers,
            // disableSendTokenisationLinkSendButton,
            // disableUpdatePaymentPlanSubmitButton,
            // manualPaymentForm,
            // manualPaymentStage,
            // manualPaymentTransctionStatus,
            // paymentPlanStatusData,
            showPdfDialog,
            showRefundPaymentsDialog,
            showRequestSignatureDialog,
            showScheduleDeliveryDialog,
            showSendDeliveryInviteDialog,
            // sendTokenisationLinkForm,
            // sendTokenisationLinkStage,
            // showControlDeviceDialog,
            // showManualPaymentDialog,
            showSignatureDialog,
            showStandingOrderPaymentDialog,
            standingOrderPaymentData,
            // showSendTokenisationLinkDialog,
            // showUpdatePaymentPlanStatusDialog,
            // value,
        } = this.state;

        let currentAgreement = {};
        let currentAgreementRecurringPaymentsAuthorisedQuantity = null;
        let currentAgreementRecurringPaymentsDeclinedQuantity = null;
        let currentAgreementRecurringPaymentsQuantity = null;
        let currentAgreementLead = null;
        let currentAgreementEstimatedCompletionDate = null;
        let currentAgreementBalance = null;
        let actualUnrefundedPayments = null;
        let refundableActualPayments;
        let totalActualUnrefundedPaymentsAmount = null;
        let totalRefundableActualPaymentsAmount = null;
        let outstandingPreDeliveryPayments = null;
        // let currentAgreementAdvancePaymentPaid = null;
        // let currentAgreementAgentFeePaid = null;
        // let currentAgreementCancelled = null;
        // let currentAgreementCollectableAgentFee = null;
        // let currentAgreementDeliveryChargePaid = null;
        // let currentAgreementFullyDelivered = null;
        // let currentPaymentPlanAuthorisedRecurringPayments = null;
        // let currentPaymentPlanCancelled = null;

        if (get(contact, `agreements[${currentAgreementTab}]`)) {
            currentAgreement = contact.agreements[currentAgreementTab];
            currentAgreementRecurringPaymentsAuthorisedQuantity = currentAgreement.authorisedPaymentSummary.filter((payment) => payment).length;
            currentAgreementRecurringPaymentsDeclinedQuantity = currentAgreement.authorisedPaymentSummary.filter((payment) => payment === false).length;
            currentAgreementRecurringPaymentsQuantity = currentAgreement.authorisedPaymentSummary.length;
            currentAgreementLead = contact.leads.find((lead) => lead.code === currentAgreement.leadCode);
            currentAgreementBalance = currentAgreement.repaymentAmount - currentAgreement.paidAmount;

            // const numberOfOutstandingPayments = (Math.ceil((currentAgreement.plan.repaymentAmount - currentAgreement.plan.paidAmount) / currentAgreement.plan.recurringPaymentAmount)) - 1;
            const numberOfOutstandingPayments = (Math.ceil((currentAgreement.plan.repaymentAmount - currentAgreement.plan.paidAmount) / currentAgreement.plan.recurringPaymentAmount)) - 1;
            console.log('>>> numberOfOutstandingPayments:', numberOfOutstandingPayments);
            let scheduledFrequencyString;
            let scheduledFrequencyMultiplier;
            switch (currentAgreement.plan.scheduleFrequency) {
            case 'WEEKLY': {
                scheduledFrequencyString = 'weeks';
                scheduledFrequencyMultiplier = 1;
                break;
            }
            case 'FORTNIGHTLY': {
                scheduledFrequencyString = 'days';
                scheduledFrequencyMultiplier = 14;
                break;
            }
            case 'MONTHLY': {
                scheduledFrequencyString = 'months';
                scheduledFrequencyMultiplier = 1;
                break;
            }
            default: {
                scheduledFrequencyString = 'weeks';
                scheduledFrequencyMultiplier = 1;
                break;
            }
            }
            // console.log('>>> scheduledFrequencyString:', scheduledFrequencyString);
            // console.log('>>> scheduledFrequencyMultiplier:', scheduledFrequencyMultiplier);

            // currentAgreementEstimatedCompletionDate = moment(currentAgreement.plan.nextPaymentDate).add(numberOfOutstandingPayments, currentAgreement.plan.scheduleFrequency === 'MONTHLY' ? 'months' : 'weeks');
            currentAgreementEstimatedCompletionDate = moment(currentAgreement.plan.nextPaymentDate).add((numberOfOutstandingPayments * scheduledFrequencyMultiplier), scheduledFrequencyString);
            // console.log('>>> ', moment(currentAgreement.plan.nextPaymentDate).add((numberOfOutstandingPayments * 4), scheduledFrequencyString).format('DD/MM/YYYY'));
            // currentAgreementEstimatedCompletionDate = moment('2024-01-23').add(8, 'weeks'').format('DD/MM/YYYY');
            // console.log('>>> currentAgreementEstimatedCompletionDate:', currentAgreementEstimatedCompletionDate);

            actualUnrefundedPayments = currentAgreement.payments.filter((payment) => (
                payment.transactionStatus === 'AUTHORISED'
                && (!['MANUALADJUSTMENT', 'STANDINGORDER', 'REVERSAL', 'REFUND'].includes(payment.source))
                && payment.amount > payment.refundedAmount
                && !payment.reversalPaymentID
            ));

            refundableActualPayments = actualUnrefundedPayments.filter((payment) => (
                payment.amount > payment.refundedAmount
                && moment(currentDate).diff(payment.paymentDateTime, 'years') < 2
            ));

            totalActualUnrefundedPaymentsAmount = actualUnrefundedPayments.reduce((acc, payment) => acc + (payment.amount - payment.refundedAmount), 0);
            totalRefundableActualPaymentsAmount = refundableActualPayments.reduce((acc, payment) => acc + (payment.amount - payment.refundedAmount), 0);

            outstandingPreDeliveryPayments = Math.max(0, currentAgreement.minimumPreDeliveryPayments - currentAgreementRecurringPaymentsAuthorisedQuantity);
            //     currentAgreementFullyDelivered = !!currentAgreement.items.find(item => item.deliveryStatus === 1);
            //     currentAgreementCollectableAgentFee = (currentAgreement.agentCollectedFee === 1 ? 0 : currentAgreement.agentFee);
            //     currentAgreementAgentFeePaid = (currentAgreement.portalPaidAmount >= currentAgreementCollectableAgentFee);
            //     currentAgreementDeliveryChargePaid = ((currentAgreement.deliveryAmount === 0) || (currentAgreement.portalPaidAmount >= (currentAgreementCollectableAgentFee + currentAgreement.deliveryAmount)));
            //     currentAgreementAdvancePaymentPaid = ((currentAgreement.depositAmount === 0) || (currentAgreement.portalPaidAmount >= (currentAgreementCollectableAgentFee + currentAgreement.deliveryAmount + currentAgreement.depositAmount)));
            //     currentAgreementCancelled = (currentAgreement.agreementStatus === 'CANCELLED');
            //     currentPaymentPlanCancelled = (currentAgreement.Status === 'CANCELLED');
            //     currentPaymentPlanAuthorisedRecurringPayments = currentAgreement.payments.filter(payment => (payment.source === 'RecurringPayment' || payment.source === 'RetryPayment') && payment.transactionStatus === 'Authorised').length;

            // console.log('>>> currentAgreementRecurringPaymentsAuthorisedQuantity:', currentAgreementRecurringPaymentsAuthorisedQuantity);
            // console.log('>>> currentAgreementRecurringPaymentsDeclinedQuantity:', currentAgreementRecurringPaymentsDeclinedQuantity);

        // console.log('currentAgreementFullyDelivered:', currentAgreementFullyDelivered);
        // console.log('currentAgreementCollectableAgentFee:', currentAgreementCollectableAgentFee);
        // console.log('currentAgreementAgentFeePaid:', currentAgreementAgentFeePaid);
        // console.log('currentAgreementDeliveryChargePaid:', currentAgreementDeliveryChargePaid);
        // console.log('currentAgreementAdvancePaymentPaid:', currentAgreementAdvancePaymentPaid);
        // console.log('currentAgreementCancelled:', currentAgreementCancelled);
        // console.log('currentPaymentPlanCancelled:', currentPaymentPlanCancelled);
        // console.log('currentPaymentPlanAuthorisedRecurringPayments:', currentPaymentPlanAuthorisedRecurringPayments);
        // console.log('currentAgreement.items.length:', currentAgreement.items.length);
        }

        // const agreementItemsVisitStatuses = [];
        // !contactVisitsLoading && (currentAgreement.agreementItems || []).map((agreementItem) => {
        //     const agreementItemCode = agreementItem.code;
        //     contact.visits.find((visit) => {
        //         if (visit.status === 'CANCELLED') return false;
        //         visit.visitItems.find((visitItem) => {
        //             if (visitItem.rentalAgreementItemCode !== agreementItemCode) return false;
        //             agreementItemsVisitStatuses.push({
        //                 code: agreementItemCode,
        //                 status: visit.status,
        //             });
        //         });
        //     });
        // });

        let agreementItemsVisitStatuses = [];
        if (!contactVisitsLoading && !contactAgreementsLoading && !isEmpty(currentAgreement)) {
            agreementItemsVisitStatuses = currentAgreement.agreementItems.flatMap((agreementItem) => {
                const agreementItemCode = agreementItem.code;
                const value = contact.visits.flatMap((visit) => {
                    if (visit.status === 'CANCELLED') return [];
                    return visit.visitItems.flatMap((visitItem) => {
                        if (visitItem.rentalAgreementItemCode !== agreementItemCode) return [];
                        return {
                            code: agreementItemCode,
                            status: visit.status,
                            visitCode: visit.code,
                            visitItemCode: visitItem.code,
                        };
                    });
                });
                if (value.length === 0) return [{ code: agreementItemCode, status: null }];
                return value;
            });
            // console.log('>>> agreementItemsVisitStatuses:', agreementItemsVisitStatuses);
            // console.log('>>> agreementItemVisitStatus = null:', agreementItemsVisitStatuses.filter(agreementItemsVisitStatus => agreementItemsVisitStatus.status === null).length);
        }

        let currentContactHistories = null;
        if (!contactLoading && !contactHistoriesLoading) {
            currentContactHistories = contact.histories.filter((history) => history.entryType === 'AGREEMENT' && history.entryTypeReferenceCode === `RA${currentAgreement.code}`);
        }

        let currentEditAgreementItemVisit = null;
        if (currentEditAgreementItem && !contactLoading && !contactVisitsLoading && !contactAgreementsLoading && !isEmpty(currentAgreement)) {
            const visitStatus = agreementItemsVisitStatuses.find((agreementItemsVisitStatus) => agreementItemsVisitStatus.code === currentEditAgreementItem.code);
            // console.log('>>> visitStatus:', visitStatus);
            currentEditAgreementItemVisit = visitStatus ? {
                agreementItemCode: visitStatus.code,
                code: visitStatus.visitCode,
                status: visitStatus.status,
                visitItemCode: visitStatus.visitItemCode,
                visitItemQuantity: ((contact.visits.find((visit) => visit.code === visitStatus.visitCode) || {}).visitItems || {}).length,
            } : null;
            // console.log('>>> currentEditAgreementItemVisit:', currentEditAgreementItemVisit);
        }
        // console.log('>>> agreementItemVisitStatuses:', agreementItemsVisitStatuses);
        // console.log('>>> agreementItemsAvailable:', (agreementItemsVisitStatuses.filter(agreementItemsVisitStatus => (agreementItemsVisitStatus.status !== 'REBOOK' && agreementItemsVisitStatus.status !== 'SCHEDULED')) || []).length);

        // console.log('>>> Payment Cards:', this.props.paymentCards);

        // console.log('>>> currentAgreement:', currentAgreement);

        return (
            <>
                <Tile
                    tileTitle="Agreements"
                    refreshAction={fetchLeadsAgreementsData}
                    refreshing={contactAgreementsLoading}
                    tileMenuItems={[
                        {
                            action: () => this.handleRequestSignatureClick(),
                            disabled:
                                contactAgreementsLoading
                                || isEmpty(currentAgreement)
                                || (currentAgreement.signatureLink && (currentAgreement.plan.status === 'ACTIVE' || currentAgreement.plan.status === 'PENDING'))
                                || currentAgreement.status === 'COMPLETE',
                            label: 'Request Signed Contract  ',
                            section: 1,
                        },
                        {
                            action: () => this.handleCheckSelfMeasurementClick(),
                            disabled:
                                contactAgreementsLoading
                                || isEmpty(currentAgreement)
                                || (currentAgreement.selfMeasure && currentAgreement.selfMeasureCheckedByContactCode),
                            label: 'Check Self Measurements  ',
                            section: 1,
                        },
                        {
                            action: this.handleEditAgreementClick,
                            disabled:
                                contactAgreementsLoading
                                || isEmpty(currentAgreement)
                                || (currentAgreement.status === 'INCOMPLETE' && currentAgreement.plan.status === 'ACTIVE')
                                || (!userLoading && !!user.groups.find((group) => group.code === AMPLIFY5)),
                            label: 'Edit Agreement ',
                            section: 2,
                        },
                        {
                            action: this.handleEditAgreementItemsClick,
                            disabled: contactAgreementsLoading
                                || (currentAgreement.productType || {}).description != 'Flooring'
                                || !['agreementItemEdit:*', 'agreementItemEdit:write'].some((scope) => user.userScopes.includes(scope))
                                || isEmpty(currentAgreement)
                                || currentAgreement.status === 'COMPLETE',
                            label: 'Edit Agreement Items ',
                            section: 2,
                        },
                        {
                            action: this.handleEditPaymentPlanClick,
                            disabled: contactAgreementsLoading
                                || isEmpty(currentAgreement)
                                || currentAgreement.plan.source === 'LOCAL'
                                || ['COMPLETE'].some((status) => currentAgreement.plan.status === status)
                                || ['COMPLETE'].some((status) => currentAgreement.status === status),
                            label: 'Edit Payment Plan ',
                            section: 2,
                        },
                        {
                            action: this.handleManualPaymentClick,
                            disabled:
                                contactAgreementsLoading
                                || isEmpty(currentAgreement)
                                || currentAgreement.status === 'INCOMPLETE'
                                || currentAgreement.status === 'COMPLETE'
                                || currentAgreement.plan.status === 'COMPLETE',
                            label: 'Request Manual Payment ',
                            section: 2,
                        },
                        {
                            action: this.handleAutoPhonePaymentClick,
                            disabled:
                                contactAgreementsLoading
                                || isEmpty(currentAgreement)
                                || currentAgreement.status === 'INCOMPLETE'
                                || currentAgreement.status === 'COMPLETE'
                                || currentAgreement.plan.status === 'COMPLETE',
                            label: 'Request Auto Phone Payment ',
                            section: 2,
                        },
                        {
                            action: this.handleManualAdjustmentClick,
                            disabled:
                                contactAgreementsLoading
                                || isEmpty(currentAgreement)
                                || currentAgreement.status === 'INCOMPLETE'
                                || currentAgreement.status === 'COMPLETE'
                                || currentAgreement.plan.status === 'COMPLETE'
                                || (!userLoading && !!user.groups.find((group) => group.code === AMPLIFY5)),
                            label: 'Manual Adjustment ',
                            section: 2,
                        },
                        {
                            action: this.handleStandingOrderPaymentClick,
                            disabled:
                                contactAgreementsLoading
                                || isEmpty(currentAgreement)
                                || currentAgreement.status === 'INCOMPLETE'
                                || currentAgreement.status === 'COMPLETE'
                                || currentAgreement.plan.status === 'COMPLETE'
                                || currentAgreement.plan.type !== 'STANDING_ORDER'
                                || (!userLoading && !!user.groups.find((group) => group.code === AMPLIFY5)),
                            label: 'Standing Order Payment ',
                            section: 2,
                        },
                        {
                            action: this.handleRetryPaymentClick,
                            disabled:
                                contactAgreementsLoading
                                || isEmpty(currentAgreement)
                                || currentAgreement.status === 'INCOMPLETE'
                                || currentAgreement.status === 'COMPLETE'
                                || currentAgreement.plan.status === 'COMPLETE'
                                || (!userLoading && !!user.groups.find((group) => group.code === AMPLIFY5)),
                            label: 'Schedule Retry Payment ',
                            section: 2,
                        },
                        {
                            action: this.handleInterimPaymentClick,
                            disabled:
                                contactAgreementsLoading
                                || isEmpty(currentAgreement)
                                || currentAgreement.status === 'INCOMPLETE'
                                || currentAgreement.status === 'COMPLETE'
                                || currentAgreement.plan.status === 'COMPLETE',
                            label: 'Schedule Interim Payment ',
                            section: 2,
                        },
                        {
                            action: this.handleRefundPaymentsClick,
                            disabled:
                                contactAgreementsLoading
                                || !['refundReversal:*', 'refundReversal:write'].some((scope) => user.userScopes.includes(scope))
                                || isEmpty(currentAgreement)
                                || currentAgreement.plan.paidAmount <= 0
                                || refundableActualPayments.length === 0
                                || currentAgreement.status === 'CANCELLED',
                            label: 'Refund Payments ',
                            section: 2,
                        },
                        {
                            action: () => this.handlePdfDialogOpen('contract'),
                            disabled: !contactAgreementsLoading && !currentAgreement.signatureLink,
                            label: 'Show Contract  ',
                            section: 3,
                        },
                        {
                            action: () => this.handlePdfDialogOpen('termsAndConditions'),
                            disabled: true,
                            label: 'Show Terms & Conditions  ',
                            section: 3,
                        },
                        {
                            action: this.handleSignatureDialogOpen,
                            disabled: !contactAgreementsLoading && !currentAgreement.signatureLink,
                            label: 'Show Signature  ',
                            section: 3,
                        },
                        {
                            action: this.handleEmailContractClick,
                            disabled: !contactAgreementsLoading && !currentAgreement.signatureLink,
                            label: 'Email Contract and T&C\'s  ',
                            section: 3,
                        },
                        {
                            action: this.handleBuildSendContractClick,
                            disabled:
                                contactAgreementsLoading
                                || isEmpty(currentAgreement)
                                || !currentAgreement.signatureLink
                                || (!userLoading && !!user.groups.find((group) => group.code === AMPLIFY5)),

                            label: 'Build and Email Contract  ',
                            section: 3,
                        },
                        {
                            action: this.handleEmailStatementClick,
                            // disabled: !contactAgreementsLoading || isEmpty(currentAgreement) || currentAgreement.plan.type !== 'REPAYMENT',
                            label: 'Email Statement ',
                            section: 4,
                        },
                        {
                            action: this.handleScheduleDeliveryClick,
                            disabled:
                            (!contactAgreementsLoading && (currentAgreement.agreementItems || []).length === 0)
                            || (!contactAgreementsLoading && (currentAgreement.status !== 'ACTIVE' || currentAgreement.plan.status === 'CANCELLED'))
                            || (!contactAgreementsLoading && (currentAgreement.status === 'CANCELLED' || currentAgreement.plan.status === 'CANCELLED'))
                            || agreementItemsVisitStatuses.filter((agreementItemsVisitStatus) => agreementItemsVisitStatus.status === null).length === 0
                            || (!userLoading && !!user.groups.find((group) => group.code === AMPLIFY5)),
                            label: 'Schedule Delivery  ',
                            section: 5,
                        },
                        {
                            action: this.handleSendDeliveryInviteClick,
                            disabled:
                            (!contactAgreementsLoading && (currentAgreement.agreementItems || []).length === 0)
                            || (!contactAgreementsLoading && (currentAgreement.status !== 'ACTIVE' || currentAgreement.plan.status !== 'ACTIVE'))
                            || (!contactAgreementsLoading && (currentAgreement.status === 'CANCELLED' || currentAgreement.plan.status === 'CANCELLED'))
                            || agreementItemsVisitStatuses.filter((agreementItemsVisitStatus) => agreementItemsVisitStatus.status === null).length === 0
                            || (!userLoading && !!user.groups.find((group) => group.code === AMPLIFY5)),
                            label: 'Send Delivery Invite ',
                            section: 5,
                        },
                    // ...(!contactLoading
                    //     && !contactAgreementsLoading
                    //     && currentAgreement != null
                    //     && ((currentAgreement || {}).agreementStatus !== 'CANCELLED')
                    //     && (((currentAgreement || {}).Status === 'CANCELLED') || ((currentAgreement || {}).Status === 'None'))
                    //     ? [
                    //         {
                    //             label: 'Cancel Agreement',
                    //             action: this.handleCancelAgreementClick,
                    //             section: 1,
                    //         },
                    //     ]
                    //     : []
                    // ),
                    // ...(user != null
                    //     && !contactLoading
                    //     && user.accessLevel >= 3
                    //     && customer.agreements != null
                    //     && currentAgreement != null
                    //     ? [
                    //         {
                    //             label: 'Update Payment Plan Status',
                    //             action: this.handleUpdatePaymentPlanStatusClick,
                    //             section: 2,
                    //         },
                    //     ]
                    //     : []
                    // ),
                    // ...(!contactLoading
                    //     && !contactAgreementsLoading
                    //     && currentAgreement != null
                    //     && ((currentAgreement || {}).Status !== 'CANCELLED')
                    //     ? [
                    //         {
                    //             label: 'Manual Payment',
                    //             action: this.handleManualPaymentClick,
                    //             section: 2,
                    //         },
                    //     ]
                    //     : []
                    // ),
                    // ...(!contactLoading
                    //     && !contactAgreementsLoading
                    //     && currentAgreement != null
                    //     && ((currentAgreement || {}).Status !== 'CANCELLED')
                    //     ? [{
                    //         label: 'Request New Tokenisation',
                    //         action: this.handleSendTokenisationLinkClick,
                    //         section: 2,
                    //     }]
                    //     : []
                    // ),
                    // ...(!contactLoading
                    //     && !contactAgreementsLoading
                    //     && currentAgreement != null
                    //     && ((currentAgreement || {}).items != null)
                    //     && ((currentAgreement || {}).agreementStatus !== 'CANCELLED')
                    //     && ((currentAgreement || {}).items || []).filter(item => item.deliveryStatus === 0).length > 0
                    //     && user.accessLevel >= 4
                    //     ? [
                    //         {
                    //             label: 'Record Delivery',
                    //             action: this.handleRecordDeliveryClick,
                    //             section: 3,
                    //         },
                    //     ]
                    //     : []
                    // ),
                    // ...(!contactLoading
                    //     && !contactAgreementsLoading
                    //     && ((currentAgreement || {}).items != null)
                    //     // && ((currentAgreement || {}).agreementStatus !== 'CANCELLED')
                    //     && ((currentAgreement || {}).items || []).filter(item => item.controlDeviceCode !== null).length > 0
                    //     ? [
                    //         {
                    //             label: 'Control Device',
                    //             action: this.handleControlDeviceClick,
                    //             section: 3,
                    //             // disabled: true,
                    //         },
                    //     ]
                    //     : []
                    // ),
                    // ...(!contactLoading
                    //     && !contactAgreementsLoading
                    //     && currentAgreement != null
                    //     && ((currentAgreement || {}).agreementStatus !== 'CANCELLED')
                    //     && ((currentAgreement || {}).items || []).filter(item => item.deliveryStatus === 0).length > 0
                    //     ? [
                    //         {
                    //             label: 'Print delivery labels',
                    //             action: this.handlePrintDeliveryLabelsClick,
                    //             section: 3,
                    //         },
                    //     ]
                    //     : []
                    // ),
                    ]}
                    scrollToMarker="agreementsTop"
                >
                    { !contactLoading
                    && !contactAgreementsLoading
                    && !engineersLoading
                    // && !isEmpty(currentAgreement)

                    // && requestedAgreementTab != null
                    // && paymentCards !== null
                    // && agreementsAlerts !== null
                    // && (currentAgreement || {}).items !== null
                        ? (
                            <>
                                {contact.agreements.length > 0
                                    ? (
                                        <>
                                            <AppBar
                                                position="static"
                                                className={classes.tabsAppBar}
                                            >
                                                <Tabs
                                                    className={classes.tabs}
                                                    value={currentAgreementTab}
                                                    onChange={this.handleAgreementChange}
                                                >
                                                    {contact.agreements.map((agreement) => (
                                                        <Tab
                                                            className={classes.tab}
                                                            key={agreement.code}
                                                            label={
                                                                (
                                                                    <>
                                                                        <div>{`RA${agreement.code}`}</div>
                                                                        <div>{`${agreement.productType ? agreement.productType.description : 'Unknown'} ${agreement.portal1Reference ? `(${agreement.portal1Reference})` : ''}`}</div>
                                                                        <AgreementPaymentSummary
                                                                            agreementPaymentSummary={agreement.authorisedPaymentSummary}
                                                                            agreementPaymentSummaryLoading={contactAgreementsLoading}
                                                                            agreementCode={agreement.code}
                                                                            agreementBalance={(agreement.plan.repaymentAmount ? agreement.plan.repaymentAmount : 0) - (agreement.plan.paidAmount ? agreement.plan.paidAmount : 0)}
                                                                        />
                                                                        {console.log('>>> agreementRepaymentAmount, agreementPaid:', agreement.plan.repaymentAmount, agreement.plan.paidAmount)}
                                                                        {/* {this.agreementStatusIcon(
                                                                        get(agreementsAlerts.find(agreementAlerts => agreementAlerts.agreementCode === agreement.code), 'alerts', []).length > 0,
                                                                        get(agreementsAlerts.find(agreementAlerts => agreementAlerts.agreementCode === agreement.code), 'warnings', []).length > 0,
                                                                        agreement.agreementStatus === 'CANCELLED',
                                                                    )} */}

                                                                    </>
                                                                )
                                                            }
                                                        />
                                                    ))}
                                                </Tabs>
                                            </AppBar>
                                            <Paper className={classes.tabsPaper}>
                                                <ContactAgreementBadges
                                                    contact={contact}
                                                    contactAgreementsLoading={contactAgreementsLoading}
                                                    contactLoading={contactLoading}
                                                    currentAgreement={currentAgreement}
                                                />
                                                {currentAgreement.financeCompany
                                                    ? (
                                                        <div className={classes.stampWrapper}>
                                                            <div className={classes.agreementStamp}>
                                                                {currentAgreement.financeCompany}
                                                            </div>
                                                        </div>
                                                    )
                                                    : ''}

                                                {/* {
                                                !currentAgreementCancelled
                                                && currentAgreement.items.length > 0
                                                && !currentAgreementFullyDelivered
                                                && (
                                                    (currentAgreement.Type !== 'Continuous' && currentPaymentPlanAuthorisedRecurringPayments < (currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1))
                                                    || !currentAgreementAgentFeePaid
                                                    || !currentAgreementDeliveryChargePaid
                                                    || !currentAgreementAdvancePaymentPaid
                                                )
                                                    ? (
                                                        <div className={`${classes.deliveriesAlertContainer} ${classes.bumpBottom4U}`}>
                                                            <div>
                                                            DO NOT DELIVER ITEMS ON THIS AGREEMENT
                                                            </div>
                                                            {!currentAgreementAgentFeePaid
                                                                && (
                                                                    <div className={classes.deliveryAlertList}>
                                                                        <BulletIcon className={classes.bulletIcon} />
                                                                        Agent fee not fully paid
                                                                        Advance payment not fully paid
                                                                        {' '}
                                                                        (balance due: £
                                                                        {(currentAgreementCollectableAgentFee - currentAgreement.portalPaidAmount).toFixed(2)}
                                                                        )
                                                                    </div>
                                                                )
                                                            }
                                                            {!currentAgreementDeliveryChargePaid
                                                                && (
                                                                    <div className={classes.deliveryAlertList}>
                                                                        <BulletIcon className={classes.bulletIcon} />
                                                                        Delivery charge not fully paid
                                                                        {' '}
                                                                        (balance due: £
                                                                        {(currentAgreement.deliveryAmount - Math.max(0, (currentAgreement.portalPaidAmount - currentAgreementCollectableAgentFee))).toFixed(2)}
                                                                        )
                                                                    </div>
                                                                )
                                                            }
                                                            {!currentAgreementAdvancePaymentPaid
                                                                && (
                                                                    <div className={classes.deliveryAlertList}>
                                                                        <BulletIcon className={classes.bulletIcon} />
                                                                        Advance payment not fully paid
                                                                        {' '}
                                                                        (balance due: £
                                                                        {(currentAgreement.depositAmount - Math.max(0, (currentAgreement.portalPaidAmount - (currentAgreementCollectableAgentFee + currentAgreement.deliveryAmount)))).toFixed(2)}
                                                                        )
                                                                    </div>
                                                                )
                                                            }
                                                            {(currentAgreement.Type !== 'Continuous' && currentPaymentPlanAuthorisedRecurringPayments < (currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1))
                                                                && (
                                                                    <div className={classes.deliveryAlertList}>
                                                                        <BulletIcon className={classes.bulletIcon} />
                                                                        {(currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1)}
                                                                        {' '}
                                                                        Recurring payments have not been authorised
                                                                        {' '}
                                                                        (
                                                                        {(currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1) - currentPaymentPlanAuthorisedRecurringPayments}
                                                                        {' '}
                                                                        additional payments required)
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                    : null
                                            }

                                            {!currentAgreementCancelled
                                                && currentAgreement.items.length > 0
                                                && !currentAgreementFullyDelivered
                                                && (currentAgreement.Type === 'Continuous' || currentPaymentPlanAuthorisedRecurringPayments >= (currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1))
                                                && currentAgreementAgentFeePaid
                                                && currentAgreementDeliveryChargePaid
                                                && currentAgreementAdvancePaymentPaid
                                                ? (
                                                    <div className={`${classes.deliveriesOKContainer} ${classes.bumpBottom4U}`}>
                                                        IT IS OK TO DELIVER ITEMS ON THIS AGREEMENT
                                                    </div>
                                                )
                                                : null
                                            }

                                            {agreementsAlerts.find(agreementAlerts => agreementAlerts.agreementCode === customer.agreements[value].code).alerts.length > 0
                                                ? (
                                                    <React.Fragment>
                                                        <div
                                                            className={`${classes.label} ${classes.alert}`}
                                                        >
                                                    Alerts
                                                        </div>
                                                        <div className={`${classes.alertContainer} ${classes.alert}`}>
                                                            {agreementsAlerts.find(agreementAlerts => agreementAlerts.agreementCode === customer.agreements[value].code).alerts.map(alert => (
                                                                <div key={alert}>
                                                                    {alert}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </React.Fragment>
                                                )
                                                : null
                                            }
                                            {agreementsAlerts.find(agreementAlerts => agreementAlerts.agreementCode === customer.agreements[value].code).warnings.length > 0
                                                ? (
                                                    <React.Fragment>
                                                        <div
                                                            className={`${classes.label} ${classes.warning}`}
                                                        >
                                                    Warnings
                                                        </div>
                                                        <div className={`${classes.alertContainer} ${classes.warning}`}>
                                                            {agreementsAlerts.find(agreementAlerts => agreementAlerts.agreementCode === customer.agreements[value].code).warnings.map(warning => (
                                                                <div
                                                                    key={warning}
                                                                >
                                                                    {warning}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </React.Fragment>
                                                )
                                                : null
                                            }
                                            */}
                                                {currentAgreement.companyCode === 1 ? (
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        className={classes.sectionContainer}
                                                    >
                                                        <Grid item xs={4}>
                                                            {currentAgreement.costCentre ? (
                                                                <img
                                                                    alt="Cost Centre Logo"
                                                                    className={classes.costCentreLogo}
                                                                    src={currentAgreement.costCentre.logoUrl}
                                                                />
                                                            ) : null}
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <Grid item xs={12}>
                                                                <HybridTextField
                                                                    label="Cost Centre"
                                                                    text={currentAgreement.costCentre ? currentAgreement.costCentre.name : 'n/a'}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <HybridTextField
                                                                    label="Cost Centre Code"
                                                                    text={currentAgreement.costCentre ? currentAgreement.costCentre.code : 'n/a'}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ) : null}
                                                <Grid
                                                    container
                                                    spacing={0}
                                                    className={classes.sectionContainer}
                                                >
                                                    <Grid item xs={4}>
                                                        <HybridTextField
                                                            label="Recurring Paid/Unpaid On Schedule"
                                                            text={`${currentAgreementRecurringPaymentsAuthorisedQuantity}/${currentAgreementRecurringPaymentsQuantity}`}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <HybridTextField
                                                            label="Recurring Paid On Schedule"
                                                            text={currentAgreementRecurringPaymentsQuantity === 0
                                                                ? 'n/a'
                                                                : `${((currentAgreementRecurringPaymentsAuthorisedQuantity / currentAgreementRecurringPaymentsQuantity) * 100).toFixed(0)}%`}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <div className={classes.textFieldLabel}>
                                                            Payment Risk
                                                        </div>
                                                        <div className={classes.gaugeContainer}>
                                                            {currentAgreementRecurringPaymentsQuantity === 0
                                                                ? 'n/a'
                                                                : (
                                                                    <GaugeChart
                                                                        id={`gauge-chart-${contact.agreements[currentAgreementTab].code}`}
                                                                        cornerRadius={1}
                                                                        hideText
                                                                        nrOfLevels={5}
                                                                        percent={1 - (currentAgreementRecurringPaymentsAuthorisedQuantity / currentAgreementRecurringPaymentsQuantity)}
                                                                    />
                                                                )}
                                                        </div>
                                                    </Grid>
                                                    {currentAgreement.plan.type === 'REPAYMENT'
                                                        && currentAgreement.status !== 'COMPLETE'
                                                        && currentAgreement.status !== 'CANCELLED'
                                                        ? (
                                                            <Grid item xs={4}>
                                                                <HybridTextField
                                                                    label="Outstanding Pre-Delivery Payments"
                                                                    text={currentAgreement.minimumPreDeliveryPayments || currentAgreement.productType
                                                                        ? (
                                                                            `${outstandingPreDeliveryPayments}/${currentAgreement.minimumPreDeliveryPayments}`
                                                                        )
                                                                        : 'UNKNOWN'}
                                                                    fieldStyle={outstandingPreDeliveryPayments > 0 ? {
                                                                        style: 'alert',
                                                                        tooltip: `${currentAgreement.minimumPreDeliveryPayments} PRE-DELIVERY PAYMENTS REQUIRED`,
                                                                    } : false}
                                                                    alert={outstandingPreDeliveryPayments > 0}
                                                                    green={(currentAgreement.minimumPreDeliveryPayments || currentAgreement.productType) && outstandingPreDeliveryPayments === 0}
                                                                />
                                                            </Grid>
                                                        )
                                                        : null}

                                                </Grid>

                                                <Grid
                                                    container
                                                    spacing={0}
                                                    className={classes.sectionContainer}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >

                                                        <Grid item xs={12}>
                                                            <HybridTextField
                                                                label="Special Instructions"
                                                                text={currentAgreement.specialInstruction || 'None'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {((contact.leads.find((lead) => lead.agreementCode === currentAgreement.code) || {}).notes || []).length > 0
                                                    ? (
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                            className={classes.sectionContainer}
                                                        >
                                                            <div className={classes.textFieldLabel}>
                                                                Lead Notes
                                                            </div>
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                            >
                                                                {sortBy(contact.leads.find((lead) => lead.agreementCode === currentAgreement.code).notes, 'createdDateTime').map((note, i) => (
                                                                    <Grid
                                                                        key={note.code}
                                                                        container
                                                                        spacing={0}
                                                                        className={classes.leadNoteContainer}
                                                                    >
                                                                        <Grid item xs={12}>
                                                                            <HybridTextField
                                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                                label="Note"
                                                                                text={note.note}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <HybridTextField
                                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                                label="Created By"
                                                                                text={note.createdBy}
                                                                                link={`/contact/${note.createdByContactCode}`}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <HybridTextField
                                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                                label="Created Time/Date"
                                                                                text={moment(note.createdDateTime).format('HH:mm DD/MM/YYYY')}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        </Grid>
                                                    ) : null}

                                                <Grid
                                                    container
                                                    spacing={0}
                                                    className={classes.sectionContainer}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >

                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                green={currentAgreement.status === 'ACTIVE'}
                                                                label="Agreement Status"
                                                                text={titalise(currentAgreement.status || ' ')}
                                                                alert={currentAgreement.status === 'INCOMPLETE'}
                                                                fieldStyle={currentAgreement.status === 'INCOMPLETE' ? {
                                                                    style: 'alert',
                                                                    tooltip: 'AGREEMENT INCOMPLETE',
                                                                } : false}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                label="Agreement Created Time/Date"
                                                                text={moment(currentAgreement.createdDateTime).format('HH:mm DD/MM/YYYY') || ' '}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                label="Agreement Created By"
                                                                text={currentAgreement.createdBy}
                                                                link={`/contact/${currentAgreement.createdByContactCode}`}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                label="Agreement Amount"
                                                                text={`£${(currentAgreement.plan.repaymentAmount || 0).toFixed(2)}`}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                label="Paid Amount"
                                                                text={`£${(currentAgreement.plan.paidAmount || 0).toFixed(2)}`}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            {(currentAgreement.plan.type === 'REPAYMENT' || currentAgreement.plan.type === 'STANDING_ORDER')
                                                                ? (
                                                                    <HybridTextField
                                                                        cancelled={currentAgreement.status === 'CANCELLED'}
                                                                        label="Outstanding Amount"
                                                                        text={`£${((currentAgreement.plan.repaymentAmount - currentAgreement.plan.paidAmount) || 0).toFixed(2)}`}
                                                                    />
                                                                ) : ''}
                                                        </Grid>
                                                    </Grid>

                                                    {currentAgreement.selfMeasure
                                                        ? (
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                            >
                                                                <Grid item xs={4}>
                                                                    <HybridTextField
                                                                        cancelled={currentAgreement.status === 'CANCELLED'}
                                                                        label="Measurement Type"
                                                                        text={currentAgreement.selfMeasure ? 'Self' : 'Agent'}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <HybridTextField
                                                                        cancelled={currentAgreement.status === 'CANCELLED'}
                                                                        label="Checked By"
                                                                        text={currentAgreement.selfMeasureCheckedByContactCode ? currentAgreement.selfMeasureCheckedBy : 'NOT CHECKED'}
                                                                        alert={!currentAgreement.selfMeasureCheckedByContactCode}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    {currentAgreement.selfMeasureCheckedByContactCode
                                                                        ? (
                                                                            <HybridTextField
                                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                                label="Checked Time/Date"
                                                                                text={moment(currentAgreement.selfMeasureCheckedDateTime).format('HH:mm DD/MM/YYYY')}
                                                                            />
                                                                        ) : null}
                                                                </Grid>
                                                            </Grid>
                                                        ) : null}

                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                label="Discount Amount"
                                                                text={`£${(currentAgreement.discountAmount || 0).toFixed(2)}`}
                                                                alert={currentAgreement.discountAmount}
                                                            />
                                                        </Grid>
                                                        {currentAgreement.discountAmount
                                                            ? (
                                                                <Grid item xs={8}>
                                                                    <HybridTextField
                                                                        cancelled={currentAgreement.status === 'CANCELLED'}
                                                                        label="Discount Reason"
                                                                        text={currentAgreement.discountReason}
                                                                        alert={currentAgreement.discountAmount}
                                                                    />
                                                                </Grid>
                                                            )
                                                            : null}
                                                    </Grid>

                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                label="Admin Fee"
                                                                text={`£${(currentAgreement.adminFeeAmount || 0).toFixed(2)}`}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                label="Delivery Fee"
                                                                text={`£${(currentAgreement.deliveryAmount || 0).toFixed(2)}`}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                label="Advance Payment"
                                                                text={`£${(currentAgreement.advancePaymentAmount || 0).toFixed(2)}`}
                                                                alert={currentAgreement.plan.paidAmount < currentAgreement.advancePaymentAmount}
                                                                fieldStyle={currentAgreement.plan.paidAmount < currentAgreement.advancePaymentAmount ? {
                                                                    style: 'alert',
                                                                    tooltip: 'ADVANCE PAYMENT NOT PAID',
                                                                } : false}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={4}>
                                                            {/* {console.log('>>> agreementItems:', currentAgreement.agreementItems)}
                                                        {console.log('>>> agreementCode:', currentAgreement.code)} */}
                                                            <HybridTextField
                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                label="Fitting Fee"
                                                                text={
                                                                    currentAgreement.agreementItems
                                                                        ? `£${(currentAgreement.agreementItems.reduce((acc, agreementItem) => acc + agreementItem.installationAmount, 0) || 0).toFixed(2)}`
                                                                        : ''
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                label={`Agent Fee${currentAgreement.agentCollectedFee ? ' (agent collected fee)' : ''}`}
                                                                text={`£${(currentAgreement.agentFee || 0).toFixed(2)}`}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                label="Product Type"
                                                                text={currentAgreement.productType ? currentAgreement.productType.description : 'Unknown'}
                                                            // text={currentAgreement.productType.description}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                label="Installation Included"
                                                                text={
                                                                    currentAgreement.installationIncluded
                                                                        ? 'Yes'
                                                                        : 'No'
                                                                }
                                                                alert={currentAgreement.installationIncluded}
                                                                fieldStyle={currentAgreement.installationIncluded ? {
                                                                    style: 'alert',
                                                                    // tooltip: 'ADVANCE PAYMENT NOT PAID',
                                                                } : false}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                cancelled={currentAgreement.status === 'CANCELLED'}
                                                                label="Lead"
                                                                text={`L${currentAgreement.leadCode}`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid
                                                    container
                                                    spacing={0}
                                                    className={classes.sectionContainer}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                label="Payment Plan Status"
                                                                green={currentAgreement.plan.status === 'ACTIVE'}
                                                                text={titalise(currentAgreement.plan.status || ' ')}
                                                                alert={currentAgreement.plan.status === 'INCOMPLETE'}
                                                                cancelled={currentAgreement.plan.status === 'COMPLETE'}
                                                                fieldStyle={currentAgreement.plan.status === 'INCOMPLETE' ? {
                                                                    style: 'alert',
                                                                    tooltip: 'PAYMENT PLAN INCOMPLETE',
                                                                } : false}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                label="Arrears Day"
                                                                green={!currentAgreement.plan.arrearsDay}
                                                                text={currentAgreement.plan.arrearsDay || 'n/a'}
                                                                alert={currentAgreement.plan.arrearsDay}
                                                                cancelled={currentAgreement.plan.status === 'COMPLETE'}
                                                                fieldStyle={currentAgreement.plan.arrearsDay ? {
                                                                    style: 'alert',
                                                                    // tooltip: 'PAYMENT PLAN INCOMPLETE',
                                                                } : false}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >

                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                label="Payment Plan Type"
                                                                text={capitalisedSnakeCaseToTitleCase(currentAgreement.plan.type || ' ')}
                                                                disabled={currentAgreement.plan.status === 'COMPLETED'}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                label="Recurring Amount"
                                                                text={`£${(currentAgreement.plan.recurringPaymentAmount || 0).toFixed(2)}`}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                label="Payment Plan Start Date"
                                                                text={
                                                                    currentAgreement.plan.startDate
                                                                        ? (
                                                                            moment(currentAgreement.plan.startDate).format('DD/MM/YYYY') || ' '
                                                                        ) : ''
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            {currentAgreement.status === 'ACTIVE'
                                                                ? (
                                                                    <HybridTextField
                                                                        label="Next Payment Date"
                                                                        text={
                                                                            currentAgreement.plan.nextPaymentDate
                                                                                ? (
                                                                                    moment(currentAgreement.plan.nextPaymentDate).format('DD/MM/YYYY') || ' '
                                                                                ) : ''
                                                                        }
                                                                    />
                                                                )
                                                                : ''}
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            {currentAgreement.status === 'ACTIVE'
                                                                ? (
                                                                    <HybridTextField
                                                                        label="Estimated Completion Date"
                                                                        text={
                                                                            currentAgreement.plan.status === 'ACTIVE' && currentAgreementEstimatedCompletionDate
                                                                                ? (
                                                                                    moment(currentAgreementEstimatedCompletionDate).format('DD/MM/YYYY') || ' '
                                                                                ) : ''
                                                                        }
                                                                    />
                                                                )
                                                                : ''}
                                                        </Grid>
                                                    </Grid>

                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={4}>
                                                            {currentAgreement.status === 'ACTIVE'
                                                                ? (

                                                                    <HybridTextField
                                                                        label="Retry Payment Date/Time"
                                                                        text={
                                                                            currentAgreement.plan.retryPaymentDate
                                                                                ? (
                                                                                    moment(currentAgreement.plan.retryPaymentDate).format('DD/MM/YYYY HH:mm') || ' '
                                                                                ) : 'Default'
                                                                        }
                                                                    />
                                                                )
                                                                : ''}
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            {currentAgreement.status === 'ACTIVE'
                                                                ? (
                                                                    <HybridTextField
                                                                        label="Interim Payment Date"
                                                                        text={
                                                                            currentAgreement.plan.interimPaymentDate
                                                                                ? (
                                                                                    moment(currentAgreement.plan.interimPaymentDate).format('DD/MM/YYYY') || ' '
                                                                                ) : 'None'
                                                                        }
                                                                    />
                                                                )
                                                                : ''}
                                                        </Grid>
                                                    </Grid>

                                                    {/* <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                label="Arrears Amount"
                                                                text="Coming soon!"
                                                            />
                                                        </Grid>
                                                    </Grid> */}

                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                label="Schedule Frequency"
                                                                text={(currentAgreement.plan && currentAgreement.plan.scheduleFrequency) ? titalise(currentAgreement.plan.scheduleFrequency) : ''}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                label="Schedule Interval"
                                                                text={currentAgreement.plan.scheduleInterval}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                label={`Schedule Day${currentAgreement.plan.scheduleFrequency === 'TWICEMONTHLY' ? 's' : ''}`}
                                                                text={
                                                                    currentAgreement.plan.scheduleDay !== null
                                                                        ? (
                                                                            <>
                                                                                {`${(currentAgreement.plan.scheduleFrequency === 'MONTHLY' || currentAgreement.plan.scheduleFrequency === 'TWICEMONTHLY') ? moment(currentAgreement.plan.scheduleDay, 'DD').format('Do') : moment(currentAgreement.plan.nextPaymentDate).format('dddd')}${(currentAgreement.plan.scheduleFrequency === 'TWICEMONTHLY') ? `, ${moment(currentAgreement.plan.scheduleDay2, 'DD').format('Do')}` : ''}`}
                                                                            </>
                                                                            // (currentAgreement.plan.scheduleFrequency === 'MONTHLY' || currentAgreement.plan.scheduleFrequency === 'TWICEMONTHLY') ? moment(currentAgreement.plan.scheduleDay, 'DD').format('Do') : moment(currentAgreement.plan.nextPaymentDate).format('dddd')
                                                                        ) : ''
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={4}>
                                                            <HybridTextField
                                                                label="Tokenised Card"
                                                                text={currentAgreement.plan.card ? 'Yes' : 'No'}
                                                                green={currentAgreement.plan.card}
                                                                alert={!currentAgreement.plan.card}
                                                                fieldStyle={!currentAgreement.plan.card ? {
                                                                    style: 'alert',
                                                                    tooltip: 'NO TOKENISED PAYMENT CARD',
                                                                } : false}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            {currentAgreement.plan.card
                                                                ? (
                                                                    <HybridTextField
                                                                        label="Card"
                                                                        text={`${currentAgreement.plan.cardNo} exp ${currentAgreement.plan.cardExpiry}`}
                                                                    />
                                                                ) : ''}
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            {currentAgreement.plan.paymentCard // currentAgreement.plan.card
                                                                ? (
                                                                    <HybridTextField
                                                                        label="Card Issuer"
                                                                        text={`${currentAgreement.plan.paymentCard.brand} ${currentAgreement.plan.paymentCard.issuer}`}
                                                                    />
                                                                ) : ''}
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                                <ContactAgreementImages
                                                    images={(currentAgreementLead || {}).images || []}
                                                />

                                                {!contactAgreementsLoading && currentAgreement.agreementItems.length > 0
                                                    ? (
                                                        <ContactAgreementItems
                                                            agreement={currentAgreement}
                                                            agreementItemsVisitStatuses={agreementItemsVisitStatuses}
                                                            assignStockItem={assignStockItem}
                                                            branches={branches}
                                                            contactBranchCode={contact.branchCode}
                                                            visits={contact.visits}
                                                            contactAgreementsLoading={contactAgreementsLoading}
                                                            contactVisitsLoading={contactVisitsLoading}
                                                            error={errors}
                                                        />
                                                    )
                                                    : (
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                            className={classes.sectionContainer}
                                                        >
                                                            <Grid item xs={4}>
                                                                <HybridTextField
                                                                    cancelled={currentAgreement.status === 'CANCELLED'}
                                                                    alert
                                                                    // fieldStyle={agreementsAlerts.find(agreementAlerts => agreementAlerts.agreementCode === customer.agreements[value].code).badges.find(badge => (badge.label === 'Items'))}
                                                                    label="Items"
                                                                    text="None"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )}

                                                <ContactAgreementPayments
                                                    contact={this.props.contact}
                                                    payments={currentAgreement.payments}
                                                    refundPayments={this.props.refundPayments}
                                                    reversePayment={this.props.reversePayment}
                                                    user={user}
                                                />

                                                <Grid
                                                    container
                                                    spacing={0}
                                                    className={classes.updatedDateTimes}
                                                >
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                    >
                                                        <Grid item xs={2}>
                                                            <HybridTextField
                                                                label="Plan Last Updated"
                                                                text={moment(currentAgreement.plan.updatedDateTime).format('DD/MM/YYYY HH:mm') || 'n/a'}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <HybridTextField
                                                                label="Payments Last Updated"
                                                                text={moment(currentAgreement.payments.map((payment) => payment.transactionRequestResultReceivedDateTime).sort().reverse()[0]).format('DD/MM/YYYY HH:mm') || 'n/a'}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <HybridTextField
                                                                label="Source"
                                                                text={currentAgreement.plan.via || ''}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                {/*
                                            {
                                                !currentAgreementCancelled
                                                && currentAgreement.items.length > 0
                                                && !currentAgreementFullyDelivered
                                                && (
                                                    (currentAgreement.Type !== 'Continuous' && currentPaymentPlanAuthorisedRecurringPayments < (currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1))
                                                    || !currentAgreementAgentFeePaid
                                                    || !currentAgreementDeliveryChargePaid
                                                    || !currentAgreementAdvancePaymentPaid
                                                )
                                                    ? (
                                                        <div className={`${classes.deliveriesAlertContainer} ${classes.bumpBottom4U}`}>
                                                            <div>
                                                            DO NOT DELIVER ITEMS ON THIS AGREEMENT
                                                            </div>
                                                            {!currentAgreementAgentFeePaid
                                                                && (
                                                                    <div className={classes.deliveryAlertList}>
                                                                        <BulletIcon className={classes.bulletIcon} />
                                                                        Agent fee not fully paid
                                                                        Advance payment not fully paid
                                                                        {' '}
                                                                        (balance due: £
                                                                        {(currentAgreementCollectableAgentFee - currentAgreement.portalPaidAmount).toFixed(2)}
                                                                        )
                                                                    </div>
                                                                )
                                                            }
                                                            {!currentAgreementDeliveryChargePaid
                                                                && (
                                                                    <div className={classes.deliveryAlertList}>
                                                                        <BulletIcon className={classes.bulletIcon} />
                                                                        Delivery charge not fully paid
                                                                        {' '}
                                                                        (balance due: £
                                                                        {(currentAgreement.deliveryAmount - Math.max(0, (currentAgreement.portalPaidAmount - currentAgreementCollectableAgentFee))).toFixed(2)}
                                                                        )
                                                                    </div>
                                                                )
                                                            }
                                                            {!currentAgreementAdvancePaymentPaid
                                                                && (
                                                                    <div className={classes.deliveryAlertList}>
                                                                        <BulletIcon className={classes.bulletIcon} />
                                                                        Advance payment not fully paid
                                                                        {' '}
                                                                        (balance due: £
                                                                        {(currentAgreement.depositAmount - Math.max(0, (currentAgreement.portalPaidAmount - (currentAgreementCollectableAgentFee + currentAgreement.deliveryAmount)))).toFixed(2)}
                                                                        )
                                                                    </div>
                                                                )
                                                            }
                                                            {(currentAgreement.Type !== 'Continuous' && currentPaymentPlanAuthorisedRecurringPayments < (currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1))
                                                                && (
                                                                    <div className={classes.deliveryAlertList}>
                                                                        <BulletIcon className={classes.bulletIcon} />
                                                                        {(currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1)}
                                                                        {' '}
Recurring payments have not been authorised
                                                                        {' '}
                                                                        (
                                                                        {(currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1) - currentPaymentPlanAuthorisedRecurringPayments}
                                                                        {' '}
                                                                        additional payments required)
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                    : null
                                            }

                                            {!currentAgreementCancelled
                                                && currentAgreement.items.length > 0
                                                && !currentAgreementFullyDelivered
                                                && (currentAgreement.Type === 'Continuous' || currentPaymentPlanAuthorisedRecurringPayments >= (currentAgreement.ScheduleFrequency === 'Weekly' ? 3 : 1))
                                                && currentAgreementAgentFeePaid
                                                && currentAgreementDeliveryChargePaid
                                                && currentAgreementAdvancePaymentPaid
                                                ? (
                                                    <div className={`${classes.deliveriesOKContainer} ${classes.bumpBottom4U}`}>
                                                        IT IS OK TO DELIVER ITEMS ON THIS AGREEMENT
                                                    </div>
                                                )
                                                : null
                                            }

                                            <CustomerAgreementPayments
                                                payments={customer.agreements[value].payments}
                                                error={errors}
                                            />
                                        */}
                                            </Paper>

                                            <Dialog
                                                open={showCheckSelfMeasurementDialog}
                                                onClose={this.handleCloseCheckSelfMeasurementDialogClick}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogContent
                                                    className={classes.dialogContent}
                                                    className={classes.emailStatementDialog}
                                                >
                                                    <div className={classes.dialogContentContainer}>
                                                        <>
                                                            <Typography variant="h6" className={classes.bumpBottom4U}>
                                                                Check Self-Measurements
                                                            </Typography>
                                                            <DialogContentText className={classes.bumpBottom1U}>
                                                                You are confirming you have checked the customer self-measured dimensions. You will be responsible for any mismeasured returns.
                                                            </DialogContentText>
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                                direction="column"
                                                                alignItems="center"
                                                                justify="center"
                                                            >
                                                                <Grid item xs={12}>
                                                                    <Button
                                                                        className={classes.dialogButton}
                                                                        disabled={disableEmailStatementSubmitButton}
                                                                        onClick={this.handleSelfMeasurementCheckedClick}
                                                                    >
                                                                        CHECKED
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Button
                                                        onClick={this.handleCloseCheckSelfMeasurementDialogClick}
                                                        color="primary"
                                                        autoFocus
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Dialog
                                                open={showSendDeliveryInviteDialog}
                                                onClose={this.handleCloseSendDeliveryInviteDialogClick}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogContent className={classes.dialogContent}>
                                                    <div className={classes.dialogContentContainer}>
                                                        <>
                                                            <Typography variant="h6" gutterBottom>
                                                                Delivery Invite
                                                            </Typography>

                                                            <DialogContentText>
                                                                Agreement Items
                                                            </DialogContentText>
                                                            <Grid className={classes.checkboxIndent} container spacing={0}>
                                                                {currentAgreement.agreementItems.map((agreementItem) => (
                                                                    <Grid
                                                                        key={agreementItem.code}
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <FormControlLabel
                                                                            control={(
                                                                                <Checkbox
                                                                                    name={agreementItem.code}
                                                                                    id={agreementItem.code}
                                                                                    checked={sendDeliveryInviteData.agreementItems.find((itemCode) => (itemCode === agreementItem.code))}
                                                                                    onChange={this.updateSendDeliveryInviteAgreementItemCheckbox(agreementItem.code)}
                                                                                />
                                                                            )}
                                                                            label={`${agreementItem.product.modelNo} (${agreementItem.reference})`}
                                                                            disabled={agreementItemsVisitStatuses.find((agreementItemsVisitStatus) => agreementItemsVisitStatus.code === agreementItem.code && agreementItemsVisitStatus.status != null)}
                                                                        />
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={12}>
                                                                    <DatePicker
                                                                        name="dateTime"
                                                                        id="dateTime"
                                                                        margin="normal"
                                                                        label="Delivery Date"
                                                                        value={sendDeliveryInviteData.dateTime}
                                                                        onChange={this.handleSendDeliveryInviteDateChange}
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        format="DD-MM-YYYY"
                                                                        showTodayButton
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <FormControlLabel
                                                                        control={(
                                                                            <Checkbox
                                                                                name={showOnlyBranchEngineers}
                                                                                id={showOnlyBranchEngineers}
                                                                                checked={showOnlyBranchEngineers}
                                                                                onChange={this.updateShowOnlyBranchEngineersCheckbox()}
                                                                            />
                                                                        )}
                                                                        label="Show Only Branch Operatives"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <FormControl
                                                                        className={classes.menuFormControl}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                        // error={!!errors.engineerContactCode}
                                                                    >
                                                                        <InputLabel htmlFor="engineerContactCode" className={classes.inputLabel}>Scheduled Operative</InputLabel>
                                                                        <Select
                                                                            keyboard="true"
                                                                            input={(
                                                                                <OutlinedInput
                                                                                    onChange={this.updateSendDeliveryInviteData}
                                                                                    name="engineerContactCode"
                                                                                    id="engineerContactCode"
                                                                                />
                                                                            )}
                                                                            value={sendDeliveryInviteData.engineerContactCode || ''}
                                                                        >
                                                                            {engineers.filter((engineer) => engineer.engineer.branchCode === (showOnlyBranchEngineers ? contact.branchCode : engineer.engineer.branchCode)).map((engineer) => (
                                                                                [
                                                                                    <MenuItem
                                                                                        key={engineer.code}
                                                                                        value={engineer.code}
                                                                                    >
                                                                                        {`${engineer.firstname} ${engineer.surname}`}
                                                                                    </MenuItem>,
                                                                                ]
                                                                            ))}
                                                                        </Select>
                                                                        {errors.engineerContactCode && (
                                                                            <FormHelperText>{errors.engineerContactCode}</FormHelperText>
                                                                        )}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    direction="column"
                                                                    alignItems="center"
                                                                    justify="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            className={classes.dialogButton}
                                                                            onClick={this.handleEngineerSchedulerClick}
                                                                        >
                                                                            SCHEDULER
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <FormControl
                                                                        className={classes.menuFormControl}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                    >
                                                                        <InputLabel htmlFor="points" className={classes.inputLabel}>Visit Points</InputLabel>
                                                                        <Select
                                                                            keyboard="true"
                                                                            input={(
                                                                                <OutlinedInput
                                                                                    onChange={this.updateSendDeliveryInviteData}
                                                                                    name="points"
                                                                                    id="points"
                                                                                />
                                                                            )}
                                                                            value={sendDeliveryInviteData.points || ''}
                                                                        >
                                                                            {visitPointsArray.map((visitPoint) => (
                                                                                [
                                                                                    <MenuItem
                                                                                        key={visitPoint}
                                                                                        value={visitPoint}
                                                                                    >
                                                                                        {visitPoint}
                                                                                    </MenuItem>,
                                                                                ]
                                                                            ))}
                                                                        </Select>
                                                                        {errors.points && (
                                                                            <FormHelperText>{errors.points}</FormHelperText>
                                                                        )}
                                                                    </FormControl>
                                                                </Grid>

                                                                <TextField
                                                                    className={`${classes.formControl} ${classes.marginTop3}`}
                                                                    name="installationAmount"
                                                                    id="installationAmount"
                                                                    value={sendDeliveryInviteData.installationAmount}
                                                                    onChange={this.updateSendDeliveryInviteData}
                                                                    label="Installation Amount"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    error={!!errors.weeklyRentalAmount}
                                                                    helperText={errors.weeklyRentalAmount}
                                                                    InputProps={{
                                                                        startAdornment:
                                            <InputAdornment
                                                disableTypography
                                                className={classes.inputAdornment}
                                                position="start"
                                            >
                                                £
                                            </InputAdornment>,
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                                direction="column"
                                                                alignItems="center"
                                                                justify="center"
                                                            >
                                                                <Grid item xs={12}>
                                                                    <Button
                                                                        className={classes.dialogButton}
                                                                        disabled={disableSendDeliveryInviteSendButton || sendDeliveryInviteData.agreementItems.length === 0 || sendDeliveryInviteData.installationAmount === '' || !sendDeliveryInviteData.dateTime || !sendDeliveryInviteData.points}
                                                                        onClick={this.handleSendDeliveryInviteSendClick}
                                                                    >
                                                                        SEND
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>

                                                        </>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Button
                                                        onClick={this.handleCloseSendDeliveryInviteDialogClick}
                                                        color="primary"
                                                        autoFocus
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Dialog
                                                open={showScheduleDeliveryDialog}
                                                onClose={this.handleCloseScheduleDeliveryDialogClick}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogContent className={classes.dialogContent}>
                                                    <div className={classes.dialogContentContainer}>
                                                        <>
                                                            <Typography variant="h6" gutterBottom>
                                                                Schedule Delivery
                                                            </Typography>
                                                            <DialogContentText>
                                                                Agreement Items
                                                            </DialogContentText>
                                                            <Grid className={classes.checkboxIndent} container spacing={0}>
                                                                {/* {currentAgreement.agreementItems.filter(agreementItem => agreementItem.deliveryStatus === false).map(agreementItem => ( */}
                                                                {currentAgreement.agreementItems.map((agreementItem) => (
                                                                    <Grid
                                                                        key={agreementItem.code}
                                                                        item
                                                                        xs={12}
                                                                    >
                                                                        <FormControlLabel
                                                                            control={(
                                                                                <Checkbox
                                                                                    name={agreementItem.code}
                                                                                    id={agreementItem.code}
                                                                                    checked={scheduleDeliveryData.agreementItems.find((itemCode) => (itemCode === agreementItem.code))}
                                                                                    onChange={this.updateScheduleDeliveryAgreementItemCheckbox(agreementItem.code)}
                                                                                />
                                                                            )}
                                                                            label={`${agreementItem.product.modelNo} (${agreementItem.reference})`}
                                                                            disabled={agreementItemsVisitStatuses.find((agreementItemsVisitStatus) => agreementItemsVisitStatus.code === agreementItem.code && agreementItemsVisitStatus.status != null)}
                                                                        />
                                                                    </Grid>
                                                                ))}
                                                            </Grid>

                                                            <Grid container spacing={0}>
                                                                <Grid item xs={12}>
                                                                    <DatePicker
                                                                        name="dateTime"
                                                                        id="dateTime"
                                                                        margin="normal"
                                                                        label="Delivery Date"
                                                                        value={scheduleDeliveryData.dateTime}
                                                                        onChange={this.handleScheduleDeliveryDateChange}
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        format="DD-MM-YYYY"
                                                                        showTodayButton
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <FormControlLabel
                                                                        control={(
                                                                            <Checkbox
                                                                                name={showOnlyBranchEngineers}
                                                                                id={showOnlyBranchEngineers}
                                                                                checked={showOnlyBranchEngineers}
                                                                                onChange={this.updateShowOnlyBranchEngineersCheckbox()}
                                                                            />
                                                                        )}
                                                                        label="Show Only Branch Operatives"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <FormControl
                                                                        className={classes.menuFormControl}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                        // error={!!errors.engineerContactCode}
                                                                    >
                                                                        <InputLabel htmlFor="engineerContactCode" className={classes.inputLabel}>Scheduled Operative</InputLabel>
                                                                        <Select
                                                                            keyboard="true"
                                                                            input={(
                                                                                <OutlinedInput
                                                                                    onChange={this.updateScheduleDeliveryData}
                                                                                    name="engineerContactCode"
                                                                                    id="engineerContactCode"
                                                                                />
                                                                            )}
                                                                            value={scheduleDeliveryData.engineerContactCode || ''}
                                                                        >
                                                                            {engineers.filter((engineer) => engineer.engineer.branchCode === (showOnlyBranchEngineers ? contact.branchCode : engineer.engineer.branchCode)).map((engineer) => (
                                                                                [
                                                                                    <MenuItem
                                                                                        key={engineer.code}
                                                                                        value={engineer.code}
                                                                                    >
                                                                                        {`${engineer.firstname} ${engineer.surname}`}
                                                                                    </MenuItem>,
                                                                                ]
                                                                            ))}
                                                                        </Select>
                                                                        {errors.engineerContactCode && (
                                                                            <FormHelperText>{errors.engineerContactCode}</FormHelperText>
                                                                        )}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    direction="column"
                                                                    alignItems="center"
                                                                    justify="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            className={classes.dialogButton}
                                                                            onClick={this.handleEngineerSchedulerClick}
                                                                        >
                                                                            SCHEDULER
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormControl
                                                                    className={classes.menuFormControl}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                >
                                                                    <InputLabel htmlFor="points" className={classes.inputLabel}>Visit Points</InputLabel>
                                                                    <Select
                                                                        keyboard="true"
                                                                        input={(
                                                                            <OutlinedInput
                                                                                onChange={this.updateScheduleDeliveryData}
                                                                                name="points"
                                                                                id="points"
                                                                            />
                                                                        )}
                                                                        value={scheduleDeliveryData.points || ''}
                                                                    >
                                                                        {visitPointsArray.map((visitPoint) => (
                                                                            [
                                                                                <MenuItem
                                                                                    key={visitPoint}
                                                                                    value={visitPoint}
                                                                                >
                                                                                    {visitPoint}
                                                                                </MenuItem>,
                                                                            ]
                                                                        ))}
                                                                    </Select>
                                                                    {errors.points && (
                                                                        <FormHelperText>{errors.points}</FormHelperText>
                                                                    )}
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid
                                                                container
                                                                spacing={0}
                                                                direction="column"
                                                                alignItems="center"
                                                                justify="center"
                                                            >
                                                                <Grid item xs={12}>
                                                                    <Button
                                                                        className={classes.dialogButton}
                                                                        disabled={disableScheduleDeliverySubmitButton || scheduleDeliveryData.agreementItems.length === 0 || !scheduleDeliveryData.engineerContactCode || !scheduleDeliveryData.points}
                                                                        onClick={this.handleScheduleDeliverySaveClick}
                                                                    >
                                                                        SAVE
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>

                                                        </>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Button
                                                        onClick={this.handleCloseScheduleDeliveryDialogClick}
                                                        color="primary"
                                                        autoFocus
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            {/*
                                        <Dialog
                                            open={showUpdatePaymentPlanStatusDialog}
                                            onClose={this.handleCloseUpdatePaymentPlanStatusDialogClick}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogContent className={classes.dialogContent}>
                                                <div className={classes.dialogContentContainer}>
                                                    <React.Fragment>
                                                        <Typography variant="h6" gutterBottom>
                                                            Payment Plan Status
                                                        </Typography>
                                                        <React.Fragment>
                                                            <DialogContentText>
                                                                    Select new status...
                                                            </DialogContentText>

                                                            <FormControl
                                                                className={classes.menuFormControl}
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.title}
                                                            >
                                                                <InputLabel htmlFor="title" className={classes.inputLabel}>Status</InputLabel>
                                                                <Select
                                                                    keyboard="true"
                                                                    input={(
                                                                        <OutlinedInput
                                                                            onChange={this.updatePaymentPlanStatusData}
                                                                            name="Status"
                                                                            id="Status"
                                                                        />
                                                                    )}
                                                                    value={paymentPlanStatusData.Status || ''}
                                                                >
                                                                    <MenuItem value=""><em>None</em></MenuItem>
                                                                    {paymentPlanStatus.map(Status => (
                                                                        <MenuItem
                                                                            key={Status}
                                                                            value={Status}
                                                                        >
                                                                            {Status}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {errors.Status && (
                                                                    <FormHelperText>{errors.Status}</FormHelperText>
                                                                )}
                                                            </FormControl>
                                                            <Button
                                                                variant="contained"
                                                                className={classes.dialogButton}
                                                                disabled={disableUpdatePaymentPlanSubmitButton}
                                                                onClick={this.handleSubmitUpdatePaymentPlanStatusClick}
                                                            >
                                                            SUBMIT
                                                            </Button>
                                                        </React.Fragment>
                                                    </React.Fragment>
                                                </div>
                                            </DialogContent>
                                            <Divider className={classes.dialogActionsDivider} />
                                            <DialogActions className={classes.dialogActions}>
                                                <Button
                                                    onClick={this.handleCloseUpdatePaymentPlanStatusDialogClick}
                                                    color="primary"
                                                    autoFocus
                                                >
                                                    Close
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
 */}
                                            <Dialog
                                                open={showPdfDialog}
                                                onClose={this.handlePdfDialogClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                                maxWidth="md"
                                            >
                                                <DialogContent
                                                    className={classes.pdfDialogContent}
                                                >
                                                    <div>
                                                        <>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={12}>
                                                                    <Document
                                                                        loading={<div className={classes.progressContainer}><CircularProgress className={classes.progress} variant="indeterminate" /></div>}
                                                                        file={documentType === 'contract' ? { url: `https://api.portal2.payweeklycarpets.co.uk/core/v2/files/contract/RA${currentAgreement.code}` } : { url: `https://api.portal2.payweeklycarpets.co.uk/core/v2/files/contract/RA${currentAgreement.code}` }}
                                                                        onLoadSuccess={() => this.onDocumentLoadSuccess}
                                                                    >
                                                                        {Array.from(new Array(3), (el, index) => (
                                                                            <Page
                                                                                key={`page_${index + 1}`}
                                                                                pageNumber={index + 1}
                                                                            />
                                                                        ))}
                                                                    </Document>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={6}>
                                                            <a
                                                                href={`https://api.portal2.payweeklycarpets.co.uk/core/v2/files/contract/RA${currentAgreement.code}`}
                                                                className={classes.dialogDownloadButton}
                                                                download
                                                            >
                                                                DOWNLOAD
                                                            </a>
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.dialogActionsRight}>
                                                            <Button
                                                                onClick={this.handlePdfDialogClose}
                                                                color="primary"
                                                                autoFocus
                                                            >
                                                                Close
                                                            </Button>
                                                        </Grid>
                                                    </Grid>

                                                </DialogActions>
                                            </Dialog>

                                            <Dialog
                                                open={showSignatureDialog}
                                                onClose={this.handleSignatureDialogClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                                maxWidth="md"
                                            >
                                                <DialogContent className={classes.signatureDialogContent}>
                                                    <>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                            direction="column"
                                                            alignItems="center"
                                                            justify="center"
                                                        >
                                                            <Grid item xs={12}>
                                                                <img
                                                                    className={classes.signatureImage}
                                                                    alt="Signature"
                                                                    src={currentAgreement.signatureLink}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Button
                                                        onClick={this.handleSignatureDialogClose}
                                                        color="primary"
                                                        autoFocus
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Dialog
                                                open={showBuildSendContractDialog}
                                                onClose={this.handleCloseBuildSendContractDialogClick}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogContent
                                                    className={classes.dialogContent}
                                                    className={classes.buildSendContractDialog}
                                                >
                                                    <div className={classes.dialogContentContainer}>
                                                        <>
                                                            <Typography variant="h6" gutterBottom className={classes.bumpBottom4U}>
                                                                Build and Email Contract
                                                            </Typography>
                                                            <DialogContentText>
                                                                Please note:
                                                            </DialogContentText>
                                                            <DialogContentText className={classes.bumpBottom1U}>
                                                                This feature is primarily used to create missing contracts.
                                                            </DialogContentText>
                                                            <DialogContentText className={classes.bumpBottom3U}>
                                                                The contract must not be emailed directly to customer. Email it to your personal email address and check carefully before forwarding it.
                                                            </DialogContentText>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    className={classes.formControl}
                                                                    name="sendToEmailAddress"
                                                                    id="sendToEmailAddress"
                                                                    value={buildSendContractData.sendToEmailAddress}
                                                                    onChange={this.updateBuildSendContractData}
                                                                    label="Send To Email Address"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    error={!!errors.sendToEmailAddress}
                                                                    helperText={errors.sendToEmailAddress}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                                direction="column"
                                                                alignItems="center"
                                                                justify="center"
                                                            >
                                                                <Grid item xs={12}>
                                                                    <Button
                                                                        className={classes.dialogButton}
                                                                        disabled={disableBuildSendContractSubmitButton || !buildSendContractData.sendToEmailAddress}
                                                                        onClick={this.handleBuildSendContractSendClick}
                                                                    >
                                                                        SEND
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>

                                                        </>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Button
                                                        onClick={this.handleCloseBuildSendContractDialogClick}
                                                        color="primary"
                                                        autoFocus
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Dialog
                                                open={showEmailStatementDialog}
                                                onClose={this.handleCloseEmailStatementDialogClick}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogContent
                                                    className={classes.dialogContent}
                                                    className={classes.emailStatementDialog}
                                                >
                                                    <div className={classes.dialogContentContainer}>
                                                        <>
                                                            <Typography variant="h6" className={classes.bumpBottom4U}>
                                                                Email Agreement Statement (BETA)
                                                            </Typography>
                                                            <DialogContentText className={classes.bumpBottom1U}>
                                                                This is a BETA feature and may contain errors. Please check carefully and report any issues.
                                                            </DialogContentText>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    className={classes.formControl}
                                                                    name="sendToEmailAddress"
                                                                    id="sendToEmailAddress"
                                                                    value={emailStatementData.sendToEmailAddress}
                                                                    onChange={this.updateEmailStatementData}
                                                                    label="Send To Email Address"
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    error={!!errors.sendToEmailAddress}
                                                                    helperText={errors.sendToEmailAddress}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                                direction="column"
                                                                alignItems="center"
                                                                justify="center"
                                                            >
                                                                <Grid item xs={12}>
                                                                    <Button
                                                                        className={classes.dialogButton}
                                                                        disabled={disableEmailStatementSubmitButton || !emailStatementData.sendToEmailAddress}
                                                                        onClick={this.handleEmailStatementSendClick}
                                                                    >
                                                                        SEND
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>

                                                        </>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Button
                                                        onClick={this.handleCloseEmailStatementDialogClick}
                                                        color="primary"
                                                        autoFocus
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Dialog
                                                open={showEngineerSchedulerDialog}
                                                onClose={this.handleCloseEngineerSchedulerDialogClick}
                                                // maxWidth="lg"
                                                // fullWidth
                                                maxWidth="lg"
                                            >
                                                <DialogContent
                                                    className={classes.engineerScheduleDialogContent}
                                                >
                                                    <EngineerSchedule
                                                        branchCode={contact.branchCode}
                                                        engineerContactCode={
                                                            showScheduleDeliveryDialog
                                                                ? scheduleDeliveryData.engineerContactCode
                                                                : sendDeliveryInviteData.engineerContactCode
                                                        }
                                                        date={
                                                            showScheduleDeliveryDialog
                                                                ? scheduleDeliveryData.dateTime
                                                                : sendDeliveryInviteData.dateTime
                                                        }
                                                        postcodeArea={contact.address.postcodeArea}
                                                        postcodeZone={contact.address.postcodeZone}
                                                        postcodeDistrict={contact.address.postcodeDistrict}
                                                        visitFromSchedule={this.visitFromSchedule}
                                                    />
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Button
                                                        onClick={this.handleCloseEngineerSchedulerDialogClick}
                                                        color="primary"
                                                        autoFocus
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Dialog
                                                open={showEditAgreementDialog}
                                                onClose={this.handleCloseEditAgreementDialogClick}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogContent className={classes.dialogContent}>
                                                    <div className={classes.dialogContentContainer}>
                                                        <>
                                                            <Typography variant="h6" gutterBottom>
                                                                Edit Agreement
                                                            </Typography>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={12}>
                                                                    <FormControl
                                                                        className={classes.menuFormControl}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                    >
                                                                        <InputLabel htmlFor="agreementStatus" className={classes.inputLabel}>Agreement Status</InputLabel>
                                                                        <Select
                                                                            keyboard="true"
                                                                            input={(
                                                                                <OutlinedInput
                                                                                    onChange={this.updateAgreementData}
                                                                                    name="status"
                                                                                    id="status"
                                                                                />
                                                                            )}
                                                                            value={editAgreementData.status || ''}
                                                                        >
                                                                            {/* {currentAgreement.status === 'INCOMPLETE'
                                                                                ? (
                                                                                    <MenuItem
                                                                                        value="CANCELLED"
                                                                                    >
                                                                                        Cancelled
                                                                                    </MenuItem>
                                                                                )
                                                                                : (
                                                                                    <> */}
                                                                            {agreementStatuses.map((status) => (
                                                                                [
                                                                                    <MenuItem
                                                                                        key={status}
                                                                                        value={status}
                                                                                        disabled={status === 'ACTIVE' && !currentAgreement.signatureLink}
                                                                                    >
                                                                                        {`${capitalisedSnakeCaseToTitleCase(status)}${status === 'ACTIVE' && !currentAgreement.signatureLink ? ' (No Signature - Incomplete)' : ''}`}
                                                                                    </MenuItem>,
                                                                                ]
                                                                            ))}
                                                                            {/* </>
                                                                                )} */}
                                                                        </Select>
                                                                        {errors.agreementStatus && (
                                                                            <FormHelperText>{errors.agreementStatus}</FormHelperText>
                                                                        )}
                                                                    </FormControl>
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    <FormControl
                                                                        className={classes.menuFormControl}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                    >
                                                                        <InputLabel htmlFor="createdByContactCode" className={classes.inputLabel}>Created By</InputLabel>
                                                                        <Select
                                                                            keyboard="true"
                                                                            input={(
                                                                                <OutlinedInput
                                                                                    onChange={this.updateAgreementData}
                                                                                    name="createdByContactCode"
                                                                                    id="createdByContactCode"
                                                                                />
                                                                            )}
                                                                            value={editAgreementData.createdByContactCode || ''}
                                                                        >
                                                                            {sortBy(users, ['contact.firstname', 'contact.surname']).map((contactUser) => (
                                                                                [
                                                                                    <MenuItem
                                                                                        key={contactUser.contact.code}
                                                                                        value={contactUser.contact.code}
                                                                                        // disabled={contactUser.contact.code === user.contactCode}
                                                                                    >
                                                                                        {`${contactUser.contact.firstname} ${contactUser.contact.surname}`}
                                                                                    </MenuItem>,
                                                                                ]
                                                                            ))}
                                                                            {/* </>
                                                                                )} */}
                                                                        </Select>
                                                                        {errors.createdByContactCode && (
                                                                            <FormHelperText>{errors.createdByContactCode}</FormHelperText>
                                                                        )}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <FormControl
                                                                        className={classes.menuFormControl}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                        disabled={currentAgreement.productTypeCode}
                                                                    >
                                                                        <InputLabel htmlFor="productTypeCode" className={classes.inputLabel}>Product Type</InputLabel>
                                                                        <Select
                                                                            keyboard="true"
                                                                            input={(
                                                                                <OutlinedInput
                                                                                    onChange={this.updateAgreementData}
                                                                                    name="productTypeCode"
                                                                                    id="productTypeCode"
                                                                                />
                                                                            )}
                                                                            value={editAgreementData.productTypeCode || ''}
                                                                        >
                                                                            {sortBy(productTypes, 'description').map((productType) => (
                                                                                [
                                                                                    <MenuItem
                                                                                        key={productType.code}
                                                                                        value={productType.code}
                                                                                        // disabled={productType.code === currentAgreement.productTypeCode}
                                                                                    >
                                                                                        {productType.description}
                                                                                    </MenuItem>,
                                                                                ]
                                                                            ))}
                                                                            {/* </>
                                                                                )} */}
                                                                        </Select>
                                                                        {errors.productTypeCode && (
                                                                            <FormHelperText>{errors.productTypeCode}</FormHelperText>
                                                                        )}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <FormControl
                                                                        className={classes.menuFormControl}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                    >
                                                                        <InputLabel htmlFor="minimumPreDeliveryPayments" className={classes.inputLabel}>Minimum Pre-Delivery Payments</InputLabel>
                                                                        <Select
                                                                            keyboard="true"
                                                                            input={(
                                                                                <OutlinedInput
                                                                                    onChange={this.updateAgreementData}
                                                                                    name="minimumPreDeliveryPayments"
                                                                                    id="minimumPreDeliveryPayments"
                                                                                />
                                                                            )}
                                                                            value={editAgreementData.minimumPreDeliveryPayments || ''}
                                                                        >
                                                                            {[...Array(21).keys()].map((minimumPreDeliveryPayment) => (
                                                                                [
                                                                                    <MenuItem
                                                                                        key={minimumPreDeliveryPayment}
                                                                                        value={minimumPreDeliveryPayment}
                                                                                        // disabled={productType.code === currentAgreement.minimumPreDeliveryPayments}
                                                                                    >
                                                                                        {minimumPreDeliveryPayment}
                                                                                    </MenuItem>,
                                                                                ]
                                                                            ))}
                                                                            {/* </>
                                                                                )} */}
                                                                        </Select>
                                                                        {errors.minimumPreDeliveryPayments && (
                                                                            <FormHelperText>{errors.minimumPreDeliveryPayments}</FormHelperText>
                                                                        )}
                                                                    </FormControl>
                                                                </Grid>

                                                                {/* <Grid item xs={12}>
                                                                    <FormControl
                                                                        className={classes.menuFormControl}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                    // error={!!errors.planStatus}
                                                                    >
                                                                        <InputLabel htmlFor="planStatus" className={classes.inputLabel}>Payment Plan Status</InputLabel>
                                                                        <Select
                                                                            keyboard="true"
                                                                            input={(
                                                                                <OutlinedInput
                                                                                    onChange={this.updatePlanData}
                                                                                    name="status"
                                                                                    id="status"
                                                                                />
                                                                            )}
                                                                            value={editAgreementData.plan.status || ''}
                                                                        >
                                                                            {agreementStatuses.map((status) => (
                                                                                [
                                                                                    <MenuItem
                                                                                        key={status}
                                                                                        value={status}
                                                                                        // disabled={status === currentAgreement.plan.status}
                                                                                    >
                                                                                        {capitalisedSnakeCaseToTitleCase(status)}
                                                                                    </MenuItem>,
                                                                                ]
                                                                            ))}
                                                                        </Select>
                                                                        {errors.agreementStatus && (
                                                                            <FormHelperText>{errors.planStatus}</FormHelperText>
                                                                        )}
                                                                    </FormControl>
                                                                </Grid> */}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormControlLabel
                                                                    control={(
                                                                        <Checkbox
                                                                            disabled={currentAgreement.productTypeCode !== FLOORING_PRODUCT_CODE}
                                                                            name="installationIncluded"
                                                                            id="installationIncluded"
                                                                            checked={editAgreementData.installationIncluded === 1}
                                                                            onChange={this.updateAgreementDataCheckbox('installationIncluded')}
                                                                        />
                                                                    )}
                                                                    label="Installation Included"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    id="specialInstruction"
                                                                    name="specialInstruction"
                                                                    variant="outlined"
                                                                    label="Special Instructions"
                                                                    margin="normal"
                                                                    fullWidth
                                                                    value={editAgreementData.specialInstruction}
                                                                    onChange={this.updateAgreementData}
                                                                    rows={3}
                                                                    multiline
                                                                />
                                                            </Grid>

                                                            <Grid
                                                                container
                                                                spacing={0}
                                                                direction="column"
                                                                alignItems="center"
                                                                justify="center"
                                                            >
                                                                <Grid item xs={12}>
                                                                    <Button
                                                                        className={classes.dialogButton}
                                                                        disabled={disableEditAgreementSubmitButton || isEmpty(currentAgreement)}
                                                                        onClick={this.handleEditAgreementSaveClick}
                                                                    >
                                                                        SAVE
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Button
                                                        onClick={this.handleCloseEditAgreementDialogClick}
                                                        color="primary"
                                                        autoFocus
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Dialog
                                                open={showEditPaymentPlanDialog}
                                                onClose={this.handleCloseEditPaymentPlanDialogClick}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogContent className={classes.dialogContent}>
                                                    <div className={classes.dialogContentContainer}>
                                                        <>
                                                            <Typography variant="h6" gutterBottom>
                                                                Edit Payment Plan
                                                            </Typography>
                                                            <form>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <FormControl
                                                                            className={classes.menuFormControl}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                        >
                                                                            <InputLabel htmlFor="type" className={classes.inputLabel}>Payment Plan Type</InputLabel>
                                                                            <Select
                                                                                keyboard="true"
                                                                                input={(
                                                                                    <OutlinedInput
                                                                                        onChange={this.updatePaymentPlanData}
                                                                                        name="type"
                                                                                        id="type"
                                                                                        disabled={currentAgreement.plan.type === 'CONTINUOUS'}
                                                                                    />
                                                                                )}
                                                                                value={editPaymentPlanData.type || ''}
                                                                            >
                                                                                <MenuItem
                                                                                    key="continuous"
                                                                                    value="CONTINUOUS"
                                                                                    disabled
                                                                                >
                                                                                    Continuous
                                                                                </MenuItem>
                                                                                <MenuItem
                                                                                    key="repayment"
                                                                                    value="REPAYMENT"
                                                                                >
                                                                                    Repayment
                                                                                </MenuItem>
                                                                                <MenuItem
                                                                                    key="standingOrder"
                                                                                    value="STANDING_ORDER"
                                                                                >
                                                                                    Standing Order
                                                                                </MenuItem>
                                                                            </Select>
                                                                            {errors.paymentPlanStatus && (
                                                                                <FormHelperText>{errors.paymentPlanStatus}</FormHelperText>
                                                                            )}
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormControl
                                                                            className={classes.menuFormControl}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                        >
                                                                            <InputLabel htmlFor="status" className={classes.inputLabel}>Payment Plan Status</InputLabel>
                                                                            <Select
                                                                                keyboard="true"
                                                                                input={(
                                                                                    <OutlinedInput
                                                                                        onChange={this.updatePaymentPlanData}
                                                                                        name="status"
                                                                                        id="status"
                                                                                    />
                                                                                )}
                                                                                value={editPaymentPlanData.status || ''}
                                                                            >
                                                                                {agreementStatuses.filter((agreementStatus) => agreementStatus !== 'WRITTEN_OFF' && agreementStatus !== 'COMPLETE' && agreementStatus !== 'INCOMPLETE').map((status) => (
                                                                                    [
                                                                                        <MenuItem
                                                                                            key={status}
                                                                                            value={status}
                                                                                            disabled={status === currentAgreement.plan.status}
                                                                                        >
                                                                                            {capitalisedSnakeCaseToTitleCase(status)}
                                                                                        </MenuItem>,
                                                                                    ]
                                                                                ))}
                                                                            </Select>
                                                                            {errors.paymentPlanStatus && (
                                                                                <FormHelperText>{errors.paymentPlanStatus}</FormHelperText>
                                                                            )}
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            className={`${classes.formControl} ${classes.marginTop3x}`}
                                                                            name="repaymentAmount"
                                                                            id="repaymentAmount"
                                                                            value={editPaymentPlanData.repaymentAmount}
                                                                            onChange={this.updatePaymentPlanData}
                                                                            label="Repayment Amount"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                            error={!!errors.repaymentAmount}
                                                                            helperText={errors.repaymentAmount}
                                                                            InputProps={{
                                                                                startAdornment:
                                            <InputAdornment
                                                disableTypography
                                                className={classes.inputAdornment}
                                                position="start"
                                            >
                                                £
                                            </InputAdornment>,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            className={`${classes.formControl} ${classes.marginTop3x}`}
                                                                            name="recurringPaymentAmount"
                                                                            id="recurringPaymentAmount"
                                                                            value={editPaymentPlanData.recurringPaymentAmount}
                                                                            onChange={this.updatePaymentPlanData}
                                                                            label="Recurring Amount"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                            error={!!errors.recurringPaymentAmount}
                                                                            helperText={errors.recurringPaymentAmount}
                                                                            InputProps={{
                                                                                startAdornment:
                                            <InputAdornment
                                                disableTypography
                                                className={classes.inputAdornment}
                                                position="start"
                                            >
                                                £
                                            </InputAdornment>,
                                                                            }}
                                                                        />

                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormControl
                                                                            className={classes.menuFormControl}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                        >
                                                                            <InputLabel htmlFor="scheduleFrequency" className={classes.inputLabel}>Schedule Frequency</InputLabel>
                                                                            <Select
                                                                                keyboard="true"
                                                                                input={(
                                                                                    <OutlinedInput
                                                                                        onChange={this.updatePaymentPlanData}
                                                                                        name="scheduleFrequency"
                                                                                        id="scheduleFrequency"
                                                                                    />
                                                                                )}
                                                                                value={editPaymentPlanData.scheduleFrequency || ''}
                                                                            >
                                                                                {scheduleFrequencies.map((scheduleFrequency) => (
                                                                                    [
                                                                                        <MenuItem
                                                                                            key={scheduleFrequency}
                                                                                            value={scheduleFrequency}
                                                                                            disabled={scheduleFrequency === currentAgreement.plan.scheduleFrequency}
                                                                                        >
                                                                                            {capitalisedSnakeCaseToTitleCase(scheduleFrequency)}
                                                                                        </MenuItem>,
                                                                                    ]
                                                                                ))}
                                                                            </Select>
                                                                            {errors.scheduleFrequency && (
                                                                                <FormHelperText>{errors.scheduleFrequency}</FormHelperText>
                                                                            )}
                                                                        </FormControl>
                                                                    </Grid>
                                                                    {/* {(editPaymentPlanData.scheduleFrequency === 'MONTHLY' || editPaymentPlanData.scheduleFrequency === 'QUARTERLY')
                                                                        ? ( */}
                                                                    <Grid item xs={12}>
                                                                        <Grid item xs={12}>
                                                                            <TextField
                                                                                className={`${classes.formControl} ${classes.marginTop3x}`}
                                                                                name="scheduleInterval"
                                                                                id="scheduleInterval"
                                                                                value={editPaymentPlanData.scheduleInterval}
                                                                                onChange={this.updatePaymentPlanData}
                                                                                label="Schedule Interval"
                                                                                margin="normal"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                required
                                                                                error={!!errors.scheduleInterval}
                                                                                helperText={errors.scheduleInterval}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                    {/* )
                                                                        : ''} */}
                                                                    {(editPaymentPlanData.scheduleFrequency === 'MONTHLY' || editPaymentPlanData.scheduleFrequency === 'TWICEMONTHLY')
                                                                        ? (
                                                                            <Grid item xs={12}>
                                                                                <TextField
                                                                                    className={`${classes.formControl} ${classes.marginTop3x}`}
                                                                                    name="scheduleDay"
                                                                                    id="scheduleDay"
                                                                                    value={editPaymentPlanData.scheduleDay}
                                                                                    onChange={this.updatePaymentPlanData}
                                                                                    label="Scheduled Day of Month"
                                                                                    margin="normal"
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    required
                                                                                    error={!!errors.scheduleDay}
                                                                                    helperText={errors.scheduleDay}
                                                                                />
                                                                            </Grid>
                                                                        )
                                                                        : null}
                                                                    {(editPaymentPlanData.scheduleFrequency === 'TWICEMONTHLY')
                                                                        ? (
                                                                            <Grid item xs={12}>
                                                                                <TextField
                                                                                    className={`${classes.formControl} ${classes.marginTop3x}`}
                                                                                    name="scheduleDay2"
                                                                                    id="scheduleDay2"
                                                                                    value={editPaymentPlanData.scheduleDay2}
                                                                                    onChange={this.updatePaymentPlanData}
                                                                                    label="Scheduled Day 2 of Month"
                                                                                    margin="normal"
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    required
                                                                                    error={editPaymentPlanData.scheduleFrequency === 'TWICEMONTHLY' && (parseInt(editPaymentPlanData.scheduleDay2, 10) <= parseInt(editPaymentPlanData.scheduleDay, 10))}
                                                                                    helperText="Schedule Day 2 must be before Schedule Day"
                                                                                />
                                                                            </Grid>
                                                                        )
                                                                        : null}
                                                                    <Grid item xs={12}>
                                                                        <DatePicker
                                                                            name="nextPaymentDate"
                                                                            id="nextPaymentDate"
                                                                            margin="normal"
                                                                            label="Next Payment Date"
                                                                            value={editPaymentPlanData.nextPaymentDate}
                                                                            onChange={this.handleEditPaymentPlanNextPaymentDateChange}
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            format="DD-MM-YYYY"
                                                                            showTodayButton
                                                                            minDate={new Date(moment(currentDate).format('YYYY-MM-DD'))}
                                                                            error={moment(editPaymentPlanData.nextPaymentDate).diff(moment(currentDate)) <= 0}
                                                                            helperText={moment(editPaymentPlanData.nextPaymentDate).diff(moment(currentDate)) <= 0 ? 'WARNING: The selected date may cause a payment to be taken imediately' : ''}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={12}>
                                                                        <FormControl
                                                                            className={classes.menuFormControl}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                        >
                                                                            <InputLabel htmlFor="cardToken" className={classes.inputLabel}>Payment Card</InputLabel>
                                                                            <Select
                                                                                keyboard="true"
                                                                                input={(
                                                                                    <OutlinedInput
                                                                                        onChange={this.updatePaymentPlanData}
                                                                                        name="cardToken"
                                                                                        id="cardToken"
                                                                                    />
                                                                                )}
                                                                                value={editPaymentPlanData.cardToken || ''}
                                                                            >
                                                                                {(paymentCards || []).map((paymentCard) => (
                                                                                    [
                                                                                        <MenuItem
                                                                                            key={paymentCard.token}
                                                                                            value={paymentCard.token}
                                                                                            disabled={paymentCard.token === (currentAgreement.plan || {}).cardToken}
                                                                                        >
                                                                                            {`${paymentCard.cardNo}  ${paymentCard.expiry}  ${paymentCard.issuer}`}
                                                                                        </MenuItem>,
                                                                                    ]
                                                                                ))}
                                                                            </Select>
                                                                            {errors.cardToken && (
                                                                                <FormHelperText>{errors.cardToken}</FormHelperText>
                                                                            )}
                                                                        </FormControl>
                                                                    </Grid>
                                                                    {editPaymentPlanData.type !== 'STANDING_ORDER'
                                                                        ? (
                                                                            <Grid item xs={12}>
                                                                                <TextField
                                                                                    onChange={this.updatePaymentPlanData}
                                                                                    className={classes.formControl}
                                                                                    name="reason"
                                                                                    id="reason"
                                                                                    label="Reason"
                                                                                    margin="normal"
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                    required
                                                                                    multiline
                                                                                    rows="6"
                                                                                    error={!!errors.reason}
                                                                                    helperText={errors.reason}
                                                                                    autoFocus
                                                                                />
                                                                            </Grid>
                                                                        )
                                                                        : null}
                                                                </Grid>
                                                            </form>
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                                direction="column"
                                                                alignItems="center"
                                                                justify="center"
                                                            >
                                                                <Grid item xs={12}>
                                                                    <Button
                                                                        className={classes.dialogButton}
                                                                        disabled={
                                                                            disableEditPaymentPlanSubmitButton
                                                                            || isEmpty(currentAgreement)
                                                                            || (editPaymentPlanData.scheduleFrequency === 'TWICEMONTHLY' && (parseInt(editPaymentPlanData.scheduleDay2, 10) < parseInt(editPaymentPlanData.scheduleDay, 10)))
                                                                            || (editPaymentPlanData.scheduleFrequency === 'TWICEMONTHLY' && (editPaymentPlanData.scheduleDay2 === null))
                                                                            || (editPaymentPlanData.type !== 'STANDING_ORDER' && !editPaymentPlanData.reason)
                                                                        }
                                                                        onClick={this.handleEditPaymentPlanSaveClick}
                                                                    >
                                                                        SAVE
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Button
                                                        onClick={this.handleCloseEditPaymentPlanDialogClick}
                                                        color="primary"
                                                        autoFocus
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Dialog
                                                open={showRetryPaymentDialog}
                                                onClose={this.handleCloseRetryPaymentDialogClick}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogContent className={classes.dialogContent}>
                                                    <div className={classes.dialogContentContainer}>
                                                        <>
                                                            <Typography variant="h6" gutterBottom>
                                                                Schedule Retry Payment
                                                            </Typography>
                                                            <form>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            className={`${classes.formControl} ${classes.marginTop3x}`}
                                                                            name="retryPaymentAmount"
                                                                            id="retryPaymentAmount"
                                                                            value={currentAgreement.plan.recurringPaymentAmount}
                                                                            label="Retry Payment Amount"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            error={!!errors.retryPaymentAmount}
                                                                            helperText={errors.retryPaymentAmount}
                                                                            disabled
                                                                            InputProps={{
                                                                                startAdornment:
                                            <InputAdornment
                                                disableTypography
                                                className={classes.inputAdornment}
                                                position="start"
                                            >
                                                £
                                            </InputAdornment>,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <DateTimePicker
                                                                            name="retryPaymentDateTime"
                                                                            id="retryPaymentDateTime"
                                                                            margin="normal"
                                                                            label="Retry Payment Date/Time"
                                                                            value={retryPaymentData.retryPaymentDateTime}
                                                                            onChange={this.handleRetryPaymentDateTimeChange}
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            format="DD/MM/YYYY HH:mm"
                                                                            showTodayButton
                                                                            minDate={new Date(moment(currentDate).format('YYYY-MM-DD HH:mm'))}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            onChange={this.updateRetryPaymentData}
                                                                            className={classes.formControl}
                                                                            name="reason"
                                                                            id="reason"
                                                                            label="Reason"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                            multiline
                                                                            rows="6"
                                                                            error={!!errors.reason}
                                                                            helperText={errors.reason}
                                                                            autoFocus
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </form>
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                                direction="column"
                                                                alignItems="center"
                                                                justify="center"
                                                            >
                                                                <Grid item xs={12}>
                                                                    <Button
                                                                        className={classes.dialogButton}
                                                                        disabled={disableRetryPaymentSubmitButton || isEmpty(currentAgreement)}
                                                                        onClick={this.handleRetryPaymentSaveClick}
                                                                    >
                                                                        SAVE
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Button
                                                        onClick={this.handleCloseRetryPaymentDialogClick}
                                                        color="primary"
                                                        autoFocus
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Dialog
                                                open={showInterimPaymentDialog}
                                                onClose={this.handleCloseInterimPaymentDialogClick}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogContent className={classes.dialogContent}>
                                                    <div className={classes.dialogContentContainer}>
                                                        <>
                                                            <Typography variant="h6" gutterBottom>
                                                                Schedule Interim Payment
                                                            </Typography>
                                                            <form>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            className={`${classes.formControl} ${classes.marginTop3x}`}
                                                                            name="interimPaymentAmount"
                                                                            id="interimPaymentAmount"
                                                                            value={interimPaymentData.interimPaymentAmount}
                                                                            onChange={this.updateInterimPaymentData}
                                                                            label="Interim Payment Amount"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            error={!!errors.interimPaymentAmount}
                                                                            helperText={errors.interimPaymentAmount}
                                                                            InputProps={{
                                                                                startAdornment:
                                            <InputAdornment
                                                disableTypography
                                                className={classes.inputAdornment}
                                                position="start"
                                            >
                                                £
                                            </InputAdornment>,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <DateTimePicker
                                                                            name="interimPaymentDate"
                                                                            id="interimPaymentDate"
                                                                            margin="normal"
                                                                            label="Interim Payment Date"
                                                                            value={interimPaymentData.interimPaymentDate}
                                                                            onChange={this.handleInterimPaymentDateChange}
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            format="hh:mm DD/MM/YYYY"
                                                                            showTodayButton
                                                                            minDate={new Date(moment(currentDate).format('YYYY-MM-DD hh:mm'))}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            onChange={this.updateInterimPaymentData}
                                                                            className={classes.formControl}
                                                                            name="reason"
                                                                            id="reason"
                                                                            label="Reason"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                            multiline
                                                                            rows="6"
                                                                            error={!!errors.reason}
                                                                            helperText={errors.reason}
                                                                            autoFocus
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </form>
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                                direction="column"
                                                                alignItems="center"
                                                                justify="center"
                                                            >
                                                                <Grid item xs={12}>
                                                                    <Button
                                                                        className={classes.dialogButton}
                                                                        disabled={disableInterimPaymentSubmitButton || isEmpty(currentAgreement)}
                                                                        onClick={this.handleInterimPaymentSaveClick}
                                                                    >
                                                                        SAVE
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Button
                                                        onClick={this.handleCloseInterimPaymentDialogClick}
                                                        color="primary"
                                                        autoFocus
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Dialog
                                                open={showManualPaymentDialog}
                                                onClose={this.handleCloseManualPaymentDialogClick}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogContent className={classes.dialogContent}>
                                                    <div className={classes.dialogContentContainer}>
                                                        <Typography variant="h6" gutterBottom>
                                                            Request Manual Payment
                                                        </Typography>
                                                        <form>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={12}>
                                                                    <FormControl
                                                                        className={classes.menuFormControl}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                    >
                                                                        <InputLabel htmlFor="requestType" className={classes.inputLabel}>Request Type</InputLabel>
                                                                        <Select
                                                                            keyboard="true"
                                                                            input={(
                                                                                <OutlinedInput
                                                                                    onChange={this.updateManualPaymentData}
                                                                                    name="requestType"
                                                                                    id="requestType"
                                                                                />
                                                                            )}
                                                                            value={manualPaymentData.requestType || ''}
                                                                        >
                                                                            <MenuItem
                                                                                key="EXISTING_CARD"
                                                                                value="EXISTING_CARD"
                                                                                disabled={(paymentCards || []).length === 0}
                                                                            >
                                                                                Existing Card
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                key="LINK"
                                                                                value="LINK"
                                                                            >
                                                                                Link
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>
                                                                { manualPaymentData.requestType === 'EXISTING_CARD'
                                                                    ? (
                                                                        <>
                                                                            <DialogContentText className={classes.bumpTop2U}>
                                                                                Existing Cards
                                                                            </DialogContentText>
                                                                            <Grid className={classes.checkboxIndent} container spacing={0} />

                                                                            <FormControl
                                                                                component="fieldset"
                                                                                className={`${classes.bumpBottom2U} ${classes.radioButtonsContainer}`}
                                                                            >
                                                                                <RadioGroup
                                                                                    aria-label="token"
                                                                                    name="token"
                                                                                    className={classes.group}
                                                                                    value={manualPaymentData.token}
                                                                                    onChange={this.updateManualPaymentData}
                                                                                >
                                                                                    {(paymentCards || []).map((paymentCard) => (
                                                                                        <FormControlLabel
                                                                                            key={paymentCard.token}
                                                                                            value={paymentCard.token}
                                                                                            control={<Radio className={classes.cardRadio} />}
                                                                                            label={(
                                                                                                <div className={classes.issuerRadioLabel}>
                                                                                                    Card
                                                                                                    {' '}
                                                                                                    {paymentCard.cardNo}
                                                                                                    {' '}
                                                                                                    Exp
                                                                                                    {' '}
                                                                                                    {paymentCard.expiry}
                                                                                                    <div
                                                                                                        className={classes.issuerRadioDescription}
                                                                                                    >
                                                                                                        {paymentCard.brand}
                                                                                                        {' '}
                                                                                                        {paymentCard.type}
                                                                                                        {' '}
                                                                                                        -
                                                                                                        {' '}
                                                                                                        {paymentCard.issuer}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        />
                                                                                    ))}
                                                                                </RadioGroup>
                                                                            </FormControl>

                                                                        </>
                                                                    ) : ''}
                                                                { manualPaymentData.requestType === 'LINK'
                                                                    ? (
                                                                        <>
                                                                            <DialogContentText className={classes.bumpTop2U}>
                                                                                Link Type
                                                                            </DialogContentText>
                                                                            <Grid className={`${classes.bumpBottom1U} ${classes.checkboxIndent}`} container spacing={0} />

                                                                            <FormControl
                                                                                component="fieldset"
                                                                                className={`${classes.bumpBottom2U} ${classes.radioButtonsContainer}`}
                                                                            >
                                                                                <RadioGroup
                                                                                    aria-label="action"
                                                                                    name="action"
                                                                                    className={classes.group}
                                                                                    value={manualPaymentData.action}
                                                                                    onChange={this.updateManualPaymentData}
                                                                                >
                                                                                    <FormControlLabel
                                                                                        key="SMS"
                                                                                        value="SMS"
                                                                                        control={<Radio />}
                                                                                        label="SMS"
                                                                                    />
                                                                                    <FormControlLabel
                                                                                        key="EMAIL"
                                                                                        value="EMAIL"
                                                                                        control={<Radio />}
                                                                                        label="EMAIL"
                                                                                    />
                                                                                </RadioGroup>
                                                                            </FormControl>

                                                                        </>
                                                                    ) : ''}

                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        onChange={this.updateManualPaymentData}
                                                                        className={`${classes.formControl} ${classes.marginTop3x}`}
                                                                        name="amount"
                                                                        id="amount"
                                                                        value={manualPaymentData.amount}
                                                                        label="Amount"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        error={!!errors.amount}
                                                                        helperText={errors.amount}
                                                                        InputProps={{
                                                                            startAdornment:
                                            <InputAdornment
                                                disableTypography
                                                className={classes.inputAdornment}
                                                position="start"
                                            >
                                                £
                                            </InputAdornment>,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        onChange={this.updateManualPaymentData}
                                                                        className={classes.formControl}
                                                                        name="reason"
                                                                        id="reason"
                                                                        label="Reason"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                        multiline
                                                                        rows="6"
                                                                        error={!!errors.reason}
                                                                        helperText={errors.reason}
                                                                        autoFocus
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </form>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                            direction="column"
                                                            alignItems="center"
                                                            justify="center"
                                                        >
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    className={classes.dialogButton}
                                                                    disabled={disableManualPaymentSubmitButton || isEmpty(currentAgreement)}
                                                                    onClick={manualPaymentData.requestType === 'EXISTING_CARD' ? this.handleManualPaymentSubmitClick : this.handlePaymentLinkSubmitClick}
                                                                >
                                                                    SUBMIT
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Button
                                                        onClick={this.handleCloseManualPaymentDialogClick}
                                                        color="primary"
                                                        autoFocus
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Dialog
                                                open={showAutoPhonePaymentDialog}
                                                onClose={this.handleCloseAutoPhonePaymentDialogClick}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogContent className={classes.dialogContent}>
                                                    <div className={classes.dialogContentContainer}>
                                                        <Typography variant="h6" gutterBottom>
                                                            Send Auto Phone Payment Request
                                                        </Typography>
                                                        <DialogContentText className={classes.bumpTop4U}>
                                                            Are you sure you want to send:
                                                        </DialogContentText>
                                                        <DialogContentText className={classes.bumpTop2U}>
                                                            {`"You can make an payment 24/7 using your phone keypad by calling 01535609200 and selecting option 1. Your RA number is ${currentAgreement.code}"`}
                                                        </DialogContentText>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                            direction="column"
                                                            alignItems="center"
                                                            justify="center"
                                                        >
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    className={classes.dialogButton}
                                                                    onClick={this.handleAutoPhonePaymentSendClick}
                                                                    disabled={disableAutoPhonePaymentSendButton}
                                                                >
                                                                    SEND
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Button
                                                        onClick={this.handleCloseAutoPhonePaymentDialogClick}
                                                        color="primary"
                                                        autoFocus
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Dialog
                                                open={showManualAdjustmentDialog}
                                                onClose={this.handleCloseManualAdjustmentDialogClick}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogContent className={classes.dialogContent}>
                                                    <div className={classes.dialogContentContainer}>
                                                        <Typography variant="h6" gutterBottom>
                                                            Manual Adjustment
                                                        </Typography>
                                                        <form>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={12}>
                                                                    <FormControl
                                                                        className={classes.menuFormControl}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                    >
                                                                        <InputLabel htmlFor="adjustmentType" className={classes.inputLabel}>Adjustment Type</InputLabel>
                                                                        <Select
                                                                            keyboard="true"
                                                                            input={(
                                                                                <OutlinedInput
                                                                                    onChange={this.updateManualAdjustmentData}
                                                                                    name="adjustmentType"
                                                                                    id="adjustmentType"
                                                                                />
                                                                            )}
                                                                            value={manualAdjustmentData.adjustmentType || ''}
                                                                        >
                                                                            <MenuItem
                                                                                key="DEBIT"
                                                                                value="DEBIT"
                                                                            >
                                                                                Debit
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                key="CREDIT"
                                                                                value="CREDIT"
                                                                            >
                                                                                Credit
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        onChange={this.updateManualAdjustmentData}
                                                                        className={`${classes.formControl} ${classes.marginTop3x}`}
                                                                        name="amount"
                                                                        id="amount"
                                                                        value={manualAdjustmentData.amount}
                                                                        label="Amount"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        error={!!errors.amount}
                                                                        helperText={errors.amount}
                                                                        InputProps={{
                                                                            startAdornment:
                                            <InputAdornment
                                                disableTypography
                                                className={classes.inputAdornment}
                                                position="start"
                                            >
                                                £
                                            </InputAdornment>,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        onChange={this.updateManualAdjustmentData}
                                                                        className={classes.formControl}
                                                                        name="reason"
                                                                        id="reason"
                                                                        label="Reason"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                        multiline
                                                                        rows="6"
                                                                        error={!!errors.reason}
                                                                        helperText={errors.reason}
                                                                        autoFocus
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </form>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                            direction="column"
                                                            alignItems="center"
                                                            justify="center"
                                                        >
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    className={classes.dialogButton}
                                                                    disabled={
                                                                        disableManualAdjustmentSubmitButton
                                                                        || isEmpty(currentAgreement)
                                                                        || !manualAdjustmentData.adjustmentType
                                                                        || !manualAdjustmentData.amount
                                                                        || !manualAdjustmentData.reason
                                                                    }
                                                                    onClick={this.handleManualAdjustmentSubmitClick}
                                                                >
                                                                    SUBMIT
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Button
                                                        onClick={this.handleCloseManualAdjustmentDialogClick}
                                                        color="primary"
                                                        autoFocus
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Dialog
                                                open={showStandingOrderPaymentDialog}
                                                onClose={this.handleCloseStandingOrderPaymentDialogClick}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogContent className={classes.dialogContent}>
                                                    <div className={classes.dialogContentContainer}>
                                                        <Typography variant="h6" gutterBottom>
                                                            Standing Order Payment
                                                        </Typography>
                                                        <form>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        onChange={this.updateStandingOrderPaymentData}
                                                                        className={`${classes.formControl} ${classes.marginTop3x}`}
                                                                        name="amount"
                                                                        id="amount"
                                                                        value={standingOrderPaymentData.amount}
                                                                        label="Amount"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        error={!!errors.amount}
                                                                        helperText={errors.amount}
                                                                        InputProps={{
                                                                            startAdornment:
                                            <InputAdornment
                                                disableTypography
                                                className={classes.inputAdornment}
                                                position="start"
                                            >
                                                £
                                            </InputAdornment>,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        onChange={this.updateStandingOrderPaymentData}
                                                                        className={classes.formControl}
                                                                        name="reason"
                                                                        id="reason"
                                                                        label="Reason"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                        multiline
                                                                        rows="6"
                                                                        error={!!errors.reason}
                                                                        helperText={errors.reason}
                                                                        autoFocus
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </form>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                            direction="column"
                                                            alignItems="center"
                                                            justify="center"
                                                        >
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    className={classes.dialogButton}
                                                                    disabled={
                                                                        disableStandingOrderPaymentSubmitButton
                                                                        || isEmpty(currentAgreement)
                                                                        || !standingOrderPaymentData.amount
                                                                        // || !StandingOrderPaymentData.reason
                                                                    }
                                                                    onClick={this.handleStandingOrderPaymentSubmitClick}
                                                                >
                                                                    SUBMIT
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Button
                                                        onClick={this.handleCloseStandingOrderPaymentDialogClick}
                                                        color="primary"
                                                        autoFocus
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Dialog
                                                open={showRefundPaymentsDialog}
                                                onClose={this.handleCloseRefundPaymentsDialogClick}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogContent className={classes.dialogContent}>
                                                    <div className={classes.dialogContentContainer}>
                                                        <Typography variant="h6" gutterBottom>
                                                            Refund Payments
                                                        </Typography>
                                                        <form>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={12}>
                                                                    <FormControl
                                                                        className={classes.menuFormControl}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                    >
                                                                        <InputLabel htmlFor="requestType" className={classes.inputLabel}>Refund Type</InputLabel>
                                                                        <Select
                                                                            keyboard="true"
                                                                            input={(
                                                                                <OutlinedInput
                                                                                    onChange={this.updateRefundPaymentsData}
                                                                                    name="requestType"
                                                                                    id="requestType"
                                                                                />
                                                                            )}
                                                                            value={refundPaymentsData.requestType || ''}
                                                                        >
                                                                            <MenuItem
                                                                                key="ALL_PAYMENTS"
                                                                                value="ALL_PAYMENTS"
                                                                            >
                                                                                All Payments
                                                                            </MenuItem>
                                                                            <MenuItem
                                                                                key="SELECT_PAYMENTS"
                                                                                value="SELECT_PAYMENTS"
                                                                                disabled={(paymentCards || []).length === 0}
                                                                            >
                                                                                Select Payments
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </Grid>
                                                                { refundPaymentsData.requestType === 'ALL_PAYMENTS'
                                                                    ? (
                                                                        <>
                                                                            <DialogContentText className={classNames(
                                                                                classes.bumpTop2U,
                                                                                classes.dialogContentAlertText,
                                                                                classes.dialogContentTitle,
                                                                            )}
                                                                            >
                                                                                PLEASE NOTE:
                                                                            </DialogContentText>
                                                                            <Typography className={classNames(
                                                                                classes.bumpBottom2U,
                                                                                classes.dialogContentAlertText,
                                                                            )}
                                                                            >
                                                                                This will REQUEST all authorised payments against this plan, that have not been previously refunded and are less than 2 years old, be refunded. The refund process is not immediate; the result of each payment refund request will appear in the customer&#39;s account when updates are available.
                                                                            </Typography>
                                                                            <TextField
                                                                                value={`${refundableActualPayments.length} of ${actualUnrefundedPayments.length}`}
                                                                                className={classes.formControl}
                                                                                name="refundAmount"
                                                                                id="refundAmount"
                                                                                label="Able To Refund Payments"
                                                                                margin="normal"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                autoFocus
                                                                                disabled
                                                                            />
                                                                            <TextField
                                                                                value={`£${totalActualUnrefundedPaymentsAmount.toFixed(2)} of £${totalRefundableActualPaymentsAmount.toFixed(2)}`}
                                                                                className={classes.formControl}
                                                                                name="refundAmount"
                                                                                id="refundAmount"
                                                                                label="Able to refund Amount"
                                                                                margin="normal"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                autoFocus
                                                                                disabled
                                                                            />

                                                                        </>
                                                                    ) : ''}
                                                                { refundPaymentsData.requestType === 'SELECT_PAYMENTS'
                                                                    ? (
                                                                        <>
                                                                            <DialogContentText>
                                                                                Refundable Payments
                                                                            </DialogContentText>
                                                                            <Grid className={classes.checkboxIndent} container spacing={0}>
                                                                                {refundableActualPayments.map((refundableActualPayment) => (
                                                                                    <Grid
                                                                                        key={refundableActualPayment.paymentID}
                                                                                        item
                                                                                        xs={12}
                                                                                    >
                                                                                        <FormControlLabel
                                                                                            control={(
                                                                                                <Checkbox
                                                                                                    name={refundableActualPayment.paymentID}
                                                                                                    id={refundableActualPayment.paymentID}
                                                                                                    checked={refundPaymentsData.refundPayments.find((refundPayment) => (refundPayment === refundableActualPayment.paymentID))}
                                                                                                    onChange={this.updateRefundPaymentCheckbox(refundableActualPayment.paymentID, (refundableActualPayment.amount - refundableActualPayment.refundedAmount), refundableActualPayment.source, refundableActualPayment.paymentDateTime)}
                                                                                                />
                                                                                            )}
                                                                                            label={(
                                                                                                <Grid container spacing={24}>
                                                                                                    <Grid item xl={4}>
                                                                                                        £
                                                                                                        {(refundableActualPayment.amount - refundableActualPayment.refundedAmount).toFixed(2)}
                                                                                                    </Grid>
                                                                                                    <Grid item xl={4}>
                                                                                                        {moment(refundableActualPayment.paymentDateTime).format('DD/MM/YYYY HH:mm')}
                                                                                                    </Grid>
                                                                                                    <Grid item xl={4}>
                                                                                                        {paymentSourceLookUp[refundableActualPayment.source]}
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            )}
                                                                                        />
                                                                                    </Grid>
                                                                                ))}
                                                                            </Grid>
                                                                            <TextField
                                                                                value={refundPaymentsData.refundPayments.reduce((acc, payment) => acc + payment.amount, 0).toFixed(2)}
                                                                                className={classes.formControl}
                                                                                name="refundAmount"
                                                                                id="refundAmount"
                                                                                label="Total Refund Amount"
                                                                                margin="normal"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                error={!!errors.reason}
                                                                                helperText={errors.reason}
                                                                                autoFocus
                                                                                disabled
                                                                                InputProps={{
                                                                                    startAdornment:
                                                                            <InputAdornment
                                                                                disableTypography
                                                                                className={classes.inputAdornment}
                                                                                position="start"
                                                                            >
                                                                                £
                                                                            </InputAdornment>,
                                                                                }}
                                                                            />
                                                                        </>
                                                                    ) : ''}
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        onChange={this.updateRefundPaymentsData}
                                                                        className={classes.formControl}
                                                                        name="reason"
                                                                        id="reason"
                                                                        label="Reason"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                        multiline
                                                                        rows="6"
                                                                        error={!!errors.reason}
                                                                        helperText={errors.reason}
                                                                        autoFocus
                                                                    />
                                                                </Grid>
                                                                <DialogContentText className={classes.bumpTop2U}>
                                                                    Confirm Request
                                                                </DialogContentText>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        onChange={this.updateRefundPaymentsData}
                                                                        className={classes.formControl}
                                                                        name="confirmRefund"
                                                                        id="confirmRefund"
                                                                        label="Type the word REFUND"
                                                                        margin="normal"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        error={!!errors.reason}
                                                                        helperText={errors.reason}
                                                                        autoFocus
                                                                    />
                                                                </Grid>

                                                            </Grid>
                                                        </form>
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                            direction="column"
                                                            alignItems="center"
                                                            justify="center"
                                                        >
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    className={classes.dialogButton}
                                                                    disabled={
                                                                        disableRefundPaymentsSubmitButton
                                                                        || isEmpty(currentAgreement)
                                                                        || refundPaymentsData.confirmRefund !== 'REFUND'
                                                                        || refundPaymentsData.reason.length <= 0
                                                                        || (
                                                                            refundPaymentsData.requestType === 'SELECT_PAYMENTS'
                                                                                && refundPaymentsData.refundPayments.length === 0
                                                                        )
                                                                    }
                                                                    onClick={refundPaymentsData.requestType === 'ALL_PAYMENTS' ? this.handleRefundPaymentPlanSubmitClick : this.handleRefundPaymentsSubmitClick}
                                                                >
                                                                    SUBMIT
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </DialogContent>
                                                <DialogActions className={classes.dialogActions}>
                                                    <Button
                                                        onClick={this.handleCloseRefundPaymentsDialogClick}
                                                        color="primary"
                                                        autoFocus
                                                    >
                                                        Close
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>

                                        </>
                                    )
                                    : <div className={classes.emptyContent}>No agreements found.</div>}
                            </>
                        )
                        : (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )}

                    <Dialog
                        open={manualPaymentResult}
                        onClose={this.handleCloseManualPaymentResultDialogClick}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent className={classes.dialogContent}>
                            <div className={classes.dialogContentContainer}>
                                <Typography variant="h6" gutterBottom>
                                    Manual Payment Result
                                </Typography>
                                { manualPaymentResult === 'AUTHORISED'
                                    ? (
                                        <div className={classes.stateContainer}>
                                            <div>
                                                <SuccessIcon
                                                    className={classNames(
                                                        classes.resultIcon,
                                                        classes.success,
                                                    )}
                                                />
                                            </div>
                                            <div className={classNames(
                                                classes.resultLabel,
                                                classes.success,
                                            )}
                                            >
                                                {manualPaymentResult}
                                            </div>
                                        </div>
                                    )
                                    : ''}
                                { manualPaymentResult !== 'AUTHORISED' && manualPaymentResult !== 'PROCESSING'
                                    ? (
                                        <div className={classNames(
                                            classes.stateContainer,
                                            {
                                                [classes.error]: manualPaymentResult === 'DECLINED',
                                            },
                                            {
                                                [classes.warning]: manualPaymentResult !== 'DECLINED',
                                            },
                                        )}
                                        >
                                            <ErrorIcon className={classes.resultIcon} />
                                            <div className={classes.resultLabel}>
                                                {manualPaymentResult}
                                            </div>
                                        </div>
                                    )
                                    : ''}
                                { manualPaymentResult === 'PROCESSING'
                                    ? (

                                        <div className={classes.stateContainer}>
                                            <div className={classes.pleaseWaitSpinnerContainer}>
                                                <CircularProgress className={classes.resultprogress} variant="indeterminate" />
                                            </div>
                                            <div className={classes.pleaseWait}>
                                                PLEASE WAIT
                                            </div>
                                        </div>
                                    )
                                    : ''}

                            </div>
                        </DialogContent>
                        {manualPaymentResult !== 'PROCESSING'
                            ? (

                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleCloseManualPaymentResultDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            )
                            : null}
                    </Dialog>

                    <Dialog
                        open={showEditAgreementItemsDialog}
                        onClose={this.handleCloseEditAgreementItemsDialogClick}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent className={classes.dialogContent}>
                            <div className={classes.dialogContentContainer}>
                                <>
                                    <Typography variant="h6" gutterBottom>
                                        Edit Agreement Items
                                    </Typography>
                                    <IconButton onClick={this.handleAddAgreementItemClick}>
                                        <AddIcon className={classes.blackTableIcons} />
                                    </IconButton>

                                    <Grid container spacing={0}>
                                        <Grid item xs={12}>
                                            <Table className={classes.editAgreementItemTable} padding="dense">
                                                <TableBody>
                                                    {(currentAgreement.agreementItems || []).map((agreementItem, i) => (
                                                        <TableRow className={classes.editItemAgreementTableRow} key={agreementItem.code}>
                                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                                {`${agreementItem.quantity} x`}
                                                            </CustomTableCell>
                                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                                {`${(agreementItem.product.manufacturer || {}).name} ${agreementItem.product.modelNo}`}
                                                            </CustomTableCell>
                                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                                {agreementItem.item
                                                                    ? (
                                                                        <IconButton id={agreementItem.code} onClick={() => this.handleUnassignAgreementItemClick(agreementItem)}>
                                                                            <UnassignIcon className={classes.blackTableIcons} />
                                                                        </IconButton>
                                                                    )
                                                                    : null}
                                                            </CustomTableCell>
                                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                                <IconButton id={agreementItem.code} onClick={() => this.handleExchangeAgreementItemClick(agreementItem)}>
                                                                    <SwapIcon className={classes.blackTableIcons} />
                                                                </IconButton>
                                                            </CustomTableCell>
                                                            <CustomTableCell className={classes.customTableCell} align="left">
                                                                <IconButton id={agreementItem.code} onClick={() => this.handleDeleteAgreementItemClick(agreementItem)}>
                                                                    <DeleteIcon className={classes.blackTableIcons} />
                                                                </IconButton>
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid
                                                                container
                                                                spacing={0}
                                                                direction="column"
                                                                alignItems="center"
                                                                justify="center"
                                                            >
                                                                <Grid item xs={12}>
                                                                    <Button
                                                                        className={classes.dialogButton}
                                                                        disabled={disableEditAgreementItemsSubmitButton || isEmpty(currentAgreement)}
                                                                        onClick={this.handleAgreementItemConfirmDeleteClick}
                                                                    >
                                                                        SAVE
                                                                    </Button>
                                                                </Grid>
                                                            </Grid> */}
                                </>
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={this.handleCloseEditAgreementItemsDialogClick}
                                color="primary"
                                autoFocus
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={showDeleteAgreementItemDialog}
                        onClose={this.handleCloseDeleteAgreementItemDialogClick}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent className={classes.dialogContent}>
                            <div className={classes.dialogContentContainer}>
                                <>
                                    <Typography variant="h6" className={classes.dialogTitle}>
                                        Are You Sure?
                                    </Typography>
                                    <Grid container spacing={0}>
                                        <>
                                            {(currentEditAgreementItem || {}).item
                                                ? (
                                                    <Grid item xs={12}>
                                                        Stock item
                                                        {' '}
                                                        {`SC${currentEditAgreementItem.item.code}`}
                                                        {' '}
                                                        is currently assigned to this request.
                                                    </Grid>
                                                )
                                                : ''}
                                            {(currentEditAgreementItem || {}).item
                                                ? (
                                                    <Grid item xs={12} className={classes.editAgreementItemList}>
                                                        Located in
                                                        {' '}
                                                        {currentEditAgreementItem.item.branch}
                                                        {' '}
                                                        {currentEditAgreementItem.item.stockroom}
                                                        {' '}
                                                        {currentEditAgreementItem.item.bin}
                                                        {' '}
                                                        and will be returned to stock.
                                                    </Grid>
                                                )
                                                : ''}
                                            {currentEditAgreementItem && (currentEditAgreementItemVisit || {}).code && (currentEditAgreementItemVisit || {}).status !== 'CANCELLED'
                                                ? (
                                                    <Grid item xs={12} className={classes.editAgreementItemList}>
                                                        On Visit
                                                        {' V'}
                                                        {currentEditAgreementItemVisit.code}
                                                        {' '}
                                                        and will be removed from this visit.
                                                    </Grid>
                                                )
                                                : ''}
                                            {currentEditAgreementItem && (currentEditAgreementItemVisit || {}).visitItemQuantity === 1
                                                ? (
                                                    <Grid item xs={12} className={classes.editAgreementItemList}>
                                                        This the only Agreement Item on visit
                                                        {' V'}
                                                        {currentEditAgreementItemVisit.code}
                                                        {'. '}
                                                        Visit will be cancelled.
                                                    </Grid>
                                                )
                                                : ''}
                                        </>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={12}>
                                            <Button
                                                className={classes.dialogButton}
                                                disabled={disableAgreementItemConfirmDeleteButton || isEmpty(currentAgreement)}
                                                onClick={() => this.handleAgreementItemConfirmDeleteClick({
                                                    cancelVisit: currentEditAgreementItem && (currentEditAgreementItemVisit || {}).visitItemQuantity === 1,
                                                    removeFromVisit: currentEditAgreementItem && (currentEditAgreementItemVisit || {}).status && (currentEditAgreementItemVisit || {}).status !== 'CANCELLED',
                                                    unassignItem: !!(currentEditAgreementItem || {}).item,
                                                    visitCode: currentEditAgreementItemVisit.code,
                                                    visitItemCode: currentEditAgreementItem.visitCode,
                                                })}
                                            >
                                                CONFIRM DELETE
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={this.handleCloseDeleteAgreementItemDialogClick}
                                color="primary"
                                autoFocus
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={showUnassignAgreementItemDialog}
                        onClose={this.handleCloseUnassignAgreementItemDialogClick}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent className={classes.dialogContent}>
                            <div className={classes.dialogContentContainer}>
                                <>
                                    <Typography variant="h6" className={classes.dialogTitle}>
                                        Are You Sure?
                                    </Typography>
                                    <Grid container spacing={0}>
                                        <>
                                            {(currentEditAgreementItem || {}).item
                                                ? (
                                                    <Grid item xs={12}>
                                                        Stock item
                                                        {' '}
                                                        {`SC${currentEditAgreementItem.item.code}`}
                                                        {' '}
                                                        is currently assigned to this request.
                                                    </Grid>
                                                )
                                                : ''}
                                            {(currentEditAgreementItem || {}).item
                                                ? (
                                                    <Grid item xs={12} className={classes.editAgreementItemList}>
                                                        Located in
                                                        {' '}
                                                        {currentEditAgreementItem.item.branch}
                                                        {' '}
                                                        {currentEditAgreementItem.item.stockroom}
                                                        {' '}
                                                        {currentEditAgreementItem.item.bin}
                                                        {' '}
                                                        and will be returned to stock.
                                                    </Grid>
                                                )
                                                : ''}
                                        </>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={12}>
                                            <Button
                                                className={classes.dialogButton}
                                                disabled={disableAgreementItemConfirmUnassignButton || isEmpty(currentAgreement)}
                                                onClick={() => this.handleAgreementItemConfirmUnassignClick({
                                                    unassignItem: !!(currentEditAgreementItem || {}).item,
                                                })}
                                            >
                                                CONFIRM UNASSIGN ITEM
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={this.handleCloseUnassignAgreementItemDialogClick}
                                color="primary"
                                autoFocus
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={showRequestSignatureDialog}
                        onClose={this.handleCloseRequestSignatureDialogClick}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent className={classes.dialogContent}>
                            <div className={classes.dialogContentContainer}>
                                <Typography variant="h6" gutterBottom>
                                    Send Signature Request
                                </Typography>
                                <FormControl
                                    component="fieldset"
                                    className={`${classes.bumpBottom2U} ${classes.radioButtonsContainer}`}
                                >
                                    <RadioGroup
                                        aria-label="action"
                                        name="action"
                                        className={classes.group}
                                        value={requestSignatureData.action}
                                        onChange={this.updateRequestSignatureData}
                                    >
                                        <FormControlLabel
                                            key="SMS"
                                            value="SMS"
                                            control={<Radio />}
                                            label="SMS"
                                        />
                                        <FormControlLabel
                                            key="EMAIL"
                                            value="EMAIL"
                                            control={<Radio />}
                                            label="EMAIL"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Grid item xs={12}>
                                        <Button
                                            className={classes.dialogButton}
                                            disabled={disableRequestSignatureButton}
                                            onClick={this.handleRequestSignature}
                                        >
                                            SEND
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                onClick={this.handleCloseRequestSignatureDialogClick}
                                color="primary"
                                autoFocus
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {showAddAgreementItemDialog
                        ? (
                            <AgreementItemAddDialog
                                currentAgreement={currentAgreement}
                                handleCloseAddAgreementItemDialogClick={this.handleCloseAddAgreementItemDialogClick}
                                showAddAgreementItemDialog={showAddAgreementItemDialog}
                                addAgreementItem={this.props.addAgreementItem}
                            />
                        ) : ''}
                    {showExchangeAgreementItemDialog
                        ? (
                            <AgreementItemExchangeDialog
                                currentAgreement={currentAgreement}
                                currentEditAgreementItem={currentEditAgreementItem}
                                currentEditAgreementItemVisit={currentEditAgreementItemVisit}
                                handleCloseExchangeAgreementItemDialogClick={this.handleCloseExchangeAgreementItemDialogClick}
                                showExchangeAgreementItemDialog={showExchangeAgreementItemDialog}
                                exchangeAgreementItem={this.props.exchangeAgreementItem}
                            />
                        ) : ''}
                </Tile>
                <ContactHistory
                    addNewHistoryNote={addNewHistoryNote}
                    contact={contact}
                    contactHistoriesLoading={contactHistoriesLoading}
                    entryType="AGREEMENT"
                    entryTypeReferenceCode={isEmpty(currentAgreement) ? '' : `RA${currentAgreement.code}`}
                    fetchHistoriesData={fetchHistoriesData}
                    histories={currentContactHistories}
                    tileTitlePostfix={isEmpty(currentAgreement) ? '' : ` (RA${currentAgreement.code})`}
                    errors={errors}
                />
                {!contactLoading && !contactAgreementsLoading && !userLoading && !!(user.groups || []).find((group) => group.code === AMPLIFY5)
                    ? (
                        <AppBarBottom
                            agreementCode={(contact.agreements[currentAgreementTab] || {}).code}
                            contactTerminatedCall={contactTerminatedCall}
                            currentAgreement={currentAgreement}
                            escalateCall={escalateCall}
                            fetchNextCall={fetchNextCall}
                            handleManualPaymentClick={this.handleManualPaymentClick}
                            logCall={logCall}
                            scheduleCallBack={scheduleCallBack}
                        />
                    ) : null}
            </>
        );
    }
}

export default withSnackbar(withStyles(styles)(ContactAgreements));
